import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import DownloadIcon from "../../../Assets/Insight//downloadIcon.svg";
import { text4 } from "../../../Styles/Insights/smallCard";
import { data } from "../../../DummyData";
import {
  cellBoderStyle,
  ORBOx,
  cellStyle,
  tableStyle,
  headerText,
  ButtonBox,
  buttonStyle,
  lineSpan,
  lineSpantext,
  searchText,
  toText,
} from "../../../Styles/Insights/ContentBoxStyle";
import { optionBg } from "../../../Styles/HeadingStyle/InputSelectStyle";
import "../../../Styles/Insights/ContentBox.css"
import { useGlobalStateContext } from "../../../states/GlobalState";

const ContentBox = ({ 
  handletableDownload, 
  selectedPlant,
  setSelectedPlant, 
  color, 
  rowData, 
  reference, 
  startDate, 
  endDate, 
  setStartDate, 
  setEndDate,
  selectedState,
  setSelectedState,
  selectedCountry,
  setselectedCountry,
  handleFilterDropDown }) => {
  const { plantList, countriesList, stateList } = useGlobalStateContext()

  const itemsPerPage = 10;
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = rowData.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const changeFormat = (inputDate) => {
    // Split the input date into year, month, and day
    const [year, month, day] = inputDate.split("-");

    // Create a new Date object
    const date = new Date(year, month - 1, day);

    // Extract day, month, and year from the date object
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();

    // Form the desired format
    const formattedDate = `${dd}-${mm}-${yyyy}`;

    return formattedDate; 
  }

  const generatePageNumbers = () => {
    const numberOfRows = 10
    let pagesToShow = Math.ceil((rowData.length)/numberOfRows);
    // const pagesToShow = 5; // Show 5 page numbers at a time
    const pages = [];

    // Calculate start and end page numbers based on the current page
    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    // Adjust startPage and endPage to show 5 pages if close to the beginning or end
    if (endPage - startPage < pagesToShow - 1) {
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const boxStyles = {
    width: "fitContent",
    height: "auto",
    flexShrink: 0,
    borderRadius: "62.564px",
    borderLeft: "1px solid white",    
    borderRight: "1px solid white",  
    borderBottom: "1px solid white",
    borderTop:   color,
    boxShadow: "0px 6.417px 19.25px 0px rgba(0, 0, 0, 0.25)",
    paddingBottom: "2rem",
    paddingTop: "2rem",
  };
  
  const [buttonActive, setButtonActive] = useState(null);
  const ButtonName = ["Today", "Last Week", "Last Month"];
  const handleClickButton = (name) => {
    setButtonActive(name);
  };

  useEffect(() => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    switch (buttonActive) {
      case "Today":
        setStartDate(today.toISOString().slice(0, 10));
        setEndDate(today.toISOString().slice(0, 10));
        break;
      case "Last Week":
        setStartDate(lastWeek.toISOString().slice(0, 10));
        setEndDate(today.toISOString().slice(0, 10));
        break;
      case "Last Month":
        setStartDate(lastMonth.toISOString().slice(0, 10));
        setEndDate(today.toISOString().slice(0, 10));
        break;
      default:
        break;
    }
  }, [buttonActive]);

  return (
    <>
      <Box sx={boxStyles}>
        <Typography sx={headerText}>Most Active {reference}</Typography>
        <Box sx={ButtonBox}>
        <div className="filter-select-div">
          <p className="filter-text">Country</p>
          <div className="dropdown-options-div">
            {countriesList && countriesList?.map((item, index) => (
              <p
                key={index} 
                className={(selectedCountry.includes(item.id))? "dropdown-options selected" : "dropdown-options"}
                onClick={() => handleFilterDropDown(item.id, selectedCountry, setselectedCountry)}
              >
                {item.country}
              </p>
            ))}
          </div>
        </div>


        <div className="filter-select-div">
            <p className="filter-text">State</p>
            <div className="dropdown-options-div">
              {stateList && stateList?.map((item, index) => (
                <p
                  key={index} 
                  className={(selectedState.includes(item.id))? "dropdown-options selected" : "dropdown-options"}
                  onClick={() => handleFilterDropDown(item.id, selectedState, setSelectedState)}
                >
                  {item.state}
                </p>
              ))}
            </div>
          </div>

          <div className="filter-select-div">
            <p className="filter-text">Plant</p>
            <div className="dropdown-options-div">
              {plantList && plantList?.map((item, index) => (
                <p
                  key={index} 
                  className={(selectedPlant.includes(item.id))? "dropdown-options selected" : "dropdown-options"}
                  onClick={() => handleFilterDropDown(item.id, selectedPlant, setSelectedPlant)}
                >
                  {item.plant}
                </p>
              ))}
            </div>
          </div>

          {ButtonName.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                handleClickButton(item);
              }}
              style={{
                ...buttonStyle,
                background: buttonActive === item ? "#DA2129" : "transparent",
                color: buttonActive === item ? "#fff" : "#9D9FA2",
                boder:
                  buttonActive === item
                    ? "1.416px solid #DA2129"
                    : "1.416px solid #9D9FA2",
              }}
            >
              {item}
            </button>
          ))}
        </Box>
        <Box sx={ORBOx}>
          <span style={{ ...lineSpan }}></span>
          <span style={{ ...lineSpantext }}>or</span>
          <span style={{ ...lineSpan }}></span>
        </Box>{" "}
        <div className="date-filter-container">
          <span style={{ ...searchText }}>Search by:</span>
          <div className="date-selector-div">
            <input 
              type="date" 
              placeholder="Start Date"
              value={startDate}
              onChange={(e) => setStartDate && setStartDate(changeFormat(e.target.value))}
              className="date-selector"
            />
          </div>
          <span style={{ ...toText }}> TO</span>
          <div className="date-selector-div">
            <input 
              type="date" 
              placeholder="End Date"
              value={endDate}
              onChange={(e) => setEndDate && setEndDate(changeFormat(e.target.value))}
              className="date-selector"
            />
          </div>
        </div>
        <Box>
          <table style={{ ...tableStyle, borderBottom: "1px Dotted white" }}>
            <thead>
              <tr>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Training</th>
                <th style={cellStyle}>Assessment</th>
                <th style={cellStyle}>Checklist</th>
                <th style={cellStyle}>GuideBook</th>
                <th style={cellStyle}>Case Study</th>
                <th style={{ ...cellStyle, borderRight: "none" }}>SOP Document</th>
              </tr>
            </thead>
            <tbody>
              {rowData &&
                currentData.map((row, index) => (
                <tr key={index}>
                  <td style={cellBoderStyle}>
                    {row.facilitator}
                  </td>
                  <td style={cellBoderStyle}>{row.training}</td>
                  <td style={cellBoderStyle}>{row.assessment}</td>
                  <td style={cellBoderStyle}>{row.checklist}</td>
                  <td style={cellBoderStyle}>{row.guidebook}</td>
                  <td style={cellBoderStyle}>{row.case_study}</td>
                  <td style={{ ...cellBoderStyle, borderRight: "none" }}>
                    {row.sop_documents}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {(rowData.length > 10) && <div>
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ color: "#9D9FA2" }}
              disabled={currentPage === 1}
            >
              {`<`}
            </Button>
            {generatePageNumbers().map((pageNumber, index) => (
              <span
                key={index}
                onClick={() => handlePageChange(pageNumber)}
                style={{
                  cursor: "pointer",
                  margin: "0 5px",
                  fontWeight: pageNumber === currentPage ? "bolder" : "normal",
                  color: pageNumber === currentPage ? "red" : "white",
                }}
              >
                {pageNumber}
              </span>
            ))}
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ color: "#9D9FA2", fontSize: "15px" }}
              disabled={endIndex >= data.length}
            >
              {`>`}
            </Button>
          </div>}
        </Box>
        <Typography sx={{...text4,cursor:"pointer"}} onClick={() => handletableDownload(reference)}>
          <img src={DownloadIcon} alt="nothing" />
          Download Report
        </Typography>
      </Box>
    </>
  );
};

export default ContentBox;
