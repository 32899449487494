
  export  const tableStyle = {
    borderCollapse: 'collapse',
    overflow: "scroll"
  };

  export  const cellBoderStyle = {
    // padding: '2px',
    padding: "0.3rem 1rem",
    borderRight: '1px dotted #9D9FA2',
    margin: 0,
    width : "fit-content",
    textAlign: 'left',
    color: '#9D9FA2',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    wordWrap: "no-wrap",
    textTransform: "capitalize"
  };

  export  const cellStyle = {
    paddingInline: "1rem",
    borderBottom: '1px Dotted white',
    borderRight : '1px Dotted white',
    padding: '10px',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
  };
  export const headerText = {
    color: '#FFF',
    fontSize: '28.084px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '160.3%',
  };
  export const ButtonBox={
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around"
  }
  
  export const buttonStyle = {

    textAlign: 'center',
    fontSize: '15.821px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '82%',
    marginTop:"1rem",
    borderRadius: '76.451px',
    border:"1.416px solid #9D9FA2",
    width: '121.575px',
    height: '35.551px',
    flexShrink: 0,
  };
  export const lineSpan={
    width: '200px', borderBottom: '1px solid grey', display: 'inline-block'
  }
  export const lineSpantext={
    color: 'white', margin: '0 10px', display: 'inline-block'
  }
  export const ORBOx={
marginTop:"1rem"
  }
  export const  slectBox={
    marginTop:"2rem",
    marginBottom:"2rem",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around"
  }
  export const searchText = {
    color: '#FFF', // Wrap color value in quotes
    fontSize: '19.25px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '160.3%', /* 30.858px */
  };
  
  export const toText = {
    color: '#9D9FA2', // Wrap color value in quotes
    fontSize: '14.438px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '82%',
    paddingTop:"6px" /* 11.839px */
  };
  