import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  AssignBoxContainers,
  imageBox,
  imageBoxText,
  formRow,
  formColumn,
  contentText,
  formChild,
  } from "../../../Styles/Assign/AssignBoxStyle";
import axiosInstance from "../../../interceptor/axiosInstance";
import RedButton from "../../../globalComponents/redButton/RedButton"
import Image from "../../../Assets/assign/bg.png";
import "../../../Styles/Assign/AssignBox.css"


const AssignBox = ({ 
  data, 
  options, 
  handleOptionChange, 
  assignId,
  courseId,
  newId,
  fetchAssign,
  courseName, 
  trainingValue, 
  assessmentValue, 
  checklistValue, 
  guidebookValue, 
  handleDaysSelection,
  caseStudyValue,
  SopDocsValue }) => {
    const [ loader, setLoader ] = useState(false)

    const numberOfDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 18, 21, 24];

  const postData = () => {
    setLoader(true)
    const patchData = {
      assignment: data.assignment,
      training: data.training,
      checklist: data.checklist,
      guidebook: data.guidebook,
      casestudy: data.casestudy,
      sop_document: data.sop_document,
      user: data.user,
      course: data.course,
      refresher_days: data.refresher_days.toString()
    };
    
    
    if(!data) {
      return
    } else if (data && !data.newId) {
      axiosInstance
        .patch(`assign/user-assign/${data.id}/`, patchData)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setLoader(false)
        })
    } else if (data && data.newId) {
      const postData =  {
        assignment: data.assignment,
        training: data.training,
        checklist: data.checklist,
        guidebook: data.guidebook,
        refresher_days: data.refresher_days,
        user: data.user,
        course: data.course,
      }

      console.log(postData)

      axiosInstance
        .post("assign/user-assign/", postData)
        .then(res => {
          console.log(res)
          fetchAssign()
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setLoader(false)
        })
    }
  }

  return (
    <>
      <Box sx={AssignBoxContainers}>
        <Box sx={{ ...imageBox, backgroundImage: `url(${Image})` }}>
          <Typography sx={imageBoxText}>{courseName}</Typography>
        </Box>
      
        {/* Training Module */}
        <Box sx={{ ...formRow }} marginTop="1rem">
          <Box sx={contentText} width="30%" align="right">
            Training Module:
          </Box>
          <Box sx={formRow} width="70%">
            {options.map((option) => (
              <Box key={option} sx={formColumn}>
                <div 
                  className="checkbox-outer" 
                  onClick={() => handleOptionChange('training', option, assignId, courseId, newId)}
                >
                  {option === trainingValue &&
                  <div className="checkbox-inner"></div>}
                </div>
                <label
                  onClick={() => handleOptionChange('training', option, assignId, courseId, newId)}
                  style={{ ...formChild, marginTop: "0.4rem", textTransform: "capitalize" }}
                >
                  {option}
                </label>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Assessment */}
        <Box sx={{ ...formRow }} marginTop="1rem">
          <Box sx={contentText} width="30%" align="right">
            Assessment:
          </Box>
          <Box sx={formRow} width="70%">
            {options.map((option) => (
              <Box key={option} sx={formColumn}>
                <div 
                  className="checkbox-outer" 
                  onClick={() => handleOptionChange('assignment', option, assignId, courseId, newId)}
                >
                  {option === assessmentValue &&
                  <div className="checkbox-inner"></div>}
                </div>
                <label
                  onClick={() => handleOptionChange('assignment', option, assignId, courseId, newId)}
                  style={{ ...formChild, marginTop: "0.4rem", textTransform: "capitalize" }}
                >
                  {option}
                </label>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Guidebook */}
        <Box sx={{ ...formRow }} marginTop="1rem">
          <Box sx={contentText} width="30%" align="right">
            Guidebook:
          </Box>
          <Box sx={formRow} width="70%">
            {options.map((option) => (
              <Box key={option} sx={formColumn}>
                <div 
                  className="checkbox-outer" 
                  onClick={() => handleOptionChange('guidebook', option, assignId, courseId, newId)}
                >
                  {option === guidebookValue &&
                  <div className="checkbox-inner"></div>}
                </div>
                <label
                  onClick={() => handleOptionChange('guidebook', option, assignId, courseId, newId)}
                  style={{ ...formChild, marginTop: "0.4rem", textTransform: "capitalize" }}
                >
                  {option}
                </label>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Checklist */}
        <Box sx={{ ...formRow }} marginTop="1rem">
          <Box sx={contentText} width="30%" align="right">
            Checklist:
          </Box>
          <Box sx={formRow} width="70%">
          {options.map((option) => (
              <Box key={option} sx={formColumn}>
                <div 
                  className="checkbox-outer" 
                  onClick={() => handleOptionChange('checklist', option, assignId, courseId, newId)}
                >
                  {option === checklistValue &&
                  <div className="checkbox-inner"></div>}
                </div>
                <label
                  onClick={() => handleOptionChange('checklist', option, assignId, courseId, newId)}
                  style={{ ...formChild, marginTop: "0.4rem", textTransform: "capitalize" }}
                >
                  {option}
                </label>
              </Box>
            ))}
          </Box>
        </Box>

        {/* case study */}
        <Box sx={{ ...formRow }} marginTop="1rem">
          <Box sx={contentText} width="30%" align="right">
            Case Study:
          </Box>
          <Box sx={formRow} width="70%">
          {options.map((option) => (
              <Box key={option} sx={formColumn}>
                <div 
                  className="checkbox-outer" 
                  onClick={() => handleOptionChange('casestudy', option, assignId, courseId, newId)}
                >
                  {option === caseStudyValue &&
                  <div className="checkbox-inner"></div>}
                </div>
                <label
                  onClick={() => handleOptionChange('casestudy', option, assignId, courseId, newId)}
                  style={{ ...formChild, marginTop: "0.4rem", textTransform: "capitalize" }}
                >
                  {option}
                </label>
              </Box>
            ))}
          </Box>
        </Box>

        {/* sop documents */}
        <Box sx={{ ...formRow }} marginTop="1rem">
          <Box sx={contentText} width="30%" align="right">
            SOP Documents:
          </Box>
          <Box sx={formRow} width="70%">
          {options.map((option) => (
              <Box key={option} sx={formColumn}>
                <div 
                  className="checkbox-outer" 
                  onClick={() => handleOptionChange('sop_document', option, assignId, courseId, newId)}
                >
                  {option === SopDocsValue &&
                  <div className="checkbox-inner"></div>}
                </div>
                <label
                  onClick={() => handleOptionChange('sop_document', option, assignId, courseId, newId)}
                  style={{ ...formChild, marginTop: "0.4rem", textTransform: "capitalize" }}
                >
                  {option}
                </label>
              </Box>
            ))}
          </Box>
        </Box>

        {/* refresher date */}
        <Box marginTop={"1rem"}>
          <select
            className="month-selector-custom" 
            // style={{ ...selectStyles }}
            onChange={(e) => handleDaysSelection(e.target.value*30, assignId, courseId, newId)} 
          >
            <option 
            value="" 
            // style={{ ...optionBg }}
            >{data && data.refresher_date? data.refresher_date : "Refresher Date"}</option>
            {numberOfDays.map((item, id) => (
              <option
                value={item}
                key={id}
              >
                {item} Month{(item > 1) && "'s"}
              </option>
            ))}
          </select>
        </Box>

        {/* submit btn */}
        <Box sx={{ 
            width: "100%", 
            height: "4rem", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "flex-end" 
            }}
        >
          <RedButton loading={loader} text="Save" onClickHandler={postData}/>
        </Box>
      </Box>
    </>
  );
};

export default AssignBox;


// const filterred = array.filter(item => item.role.toLowerCase() === "admin")
// setArray(filterred)