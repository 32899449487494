import React, { useEffect } from 'react';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import heroLogo from "../../Assets/login/loginHero.png";
import bgMain from "../../Assets/login/bg-main.png";
import topImage from "../../Assets/login/topImage.png"
import redArch from "../../Assets/login/redArch.png";
import avatarIcon from "../../Assets/login/personIcon.png"
import lockicon from "../../Assets/login/lock.png"
import bgVector from "../../Assets/login/Vector.png"
import RedButton from "../../globalComponents/redButton/RedButton"
import ForgotPassword from './component/ForgotPassword';
import "../../Styles/login/Login.css"

const Login = ({ 
    heading,
    heroText,
    forgotText, 
    userEmail, 
    setUserEmail, 
    password, 
    setPassword, 
    showPassword, 
    setShowPassword, 
    handleSubmit,
    showPopUp,
    setShowPopUp,
    submitLoader,
    alertText }) => {
    // const heading = "WELCOME"
    // const heroText = "Enhance your skills and ensure safety for yourself and your co-workers";
    // const forgotText = "Forgot Password?"

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                handleSubmit();
            }
        };
        
        document.addEventListener("keydown", handleKeyPress);
        
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleSubmit]);

    return (
        <div className='login-main-div'>

        {showPopUp && 
        <div className='forgot-password-container'>
            <ForgotPassword setPopUpFormVisible={setShowPopUp}/>
        </div>}

            {alertText &&
            <div className='alert-box'>
                <div className='text'>{alertText}</div>
            </div>}

            <div className='background'>
                <div className='relative'>
                    <img 
                        src={bgMain} 
                        className='cover' 
                        alt='background' 
                    />
                    <img 
                        src={bgVector} 
                        className='left-center' 
                        alt='background' 
                    />
                    <img 
                        src={topImage} 
                        className='top' 
                        alt='background' 
                    />
                    <img 
                        src={redArch} 
                        className='bottom' 
                        alt='background' 
                    />
                </div>
            </div>

        <div className='hero-container'>
            <img src={heroLogo} className='hero-image' alt='hero' />
        </div>

        <div className='right-div'>

            <div className='form-container'>
                <p className='heading'>
                    {heading}
                </p>
                <p className='text'>
                    {heroText}
                </p>

                <div className='input-div'>
                    <label htmlFor="userEmail">User Id</label>

                    <div className='input-main'>
                        <img src={avatarIcon} className='icoon' alt='avatar' />
                        <input 
                            id="userEmail" 
                            type="email" 
                            value={userEmail} 
                            onChange={(e) => setUserEmail(e.target.value)} 
                        />
                    </div>
                </div>

                <div className='input-div'>
                    <label htmlFor="password">Password</label>

                    <div className="input-main">
                        <img src={lockicon} className='icoon' alt='lock' />
                        <input 
                            id="password" 
                            type={!!(showPassword)? "text" : "password"}
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                        />
                        <div 
                            className='show-pass-icon'
                            onClick={() => setShowPassword((prev) => !prev)}
                        >
                            {showPassword? <IoMdEyeOff /> : <IoMdEye />}
                        </div>
                    </div>
                </div>

                <p className='forgot-text'>
                    {forgotText}
                    <span onClick={() => setShowPopUp(true)}>
                        Click here
                    </span>
                </p>
                
                <div className='submit-btn'>
                    <RedButton 
                        onClickHandler={handleSubmit} 
                        text={"Login"} 
                        loading={submitLoader} 
                    />
                </div>

            </div>
        </div>

        </div>
    );
};

export default Login;
