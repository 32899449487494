import { height, width } from "@mui/system";

export const LearnBox={
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width:"100%",
    height:"5rem",
    borderBottom: '1px solid #FFF', 
    "@media (min-width: 1341px)": {
        height:"6rem",
      },
}

export const sidebarComponent={
    width:"20%",
    backgroundColor: "#000",
    height:"calc(100vh - 6rem)",
    position:"fixed",
    top:"6rem",
    zIndex:7,
    left:0,
    overflowY: "scroll",
    overflowX: "hidden",

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    /* Hide scrollbar */
    scrollbarWidth: "none", /* Firefox */
    msOverflowStyle: "none", /* IE/Edge */
    "&::-webkit-scrollbar": {
        width: 0,
        height: 0
    },
    "@media (max-width: 768px)": {
        width: "100%",
        left: 0
    }
}


export const learnText = {
    cursor: 'pointer', // Corrected property name and value
    color: '#FFF',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginLeft: 3,
    // line-height: normal; // Omit this line or use a specific value if needed
  };