import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "../../states/GlobalState";
import LearningPath from "../../Assets/Sidebar/LearningPath.svg";
import learnIcon from "../../Assets/Sidebar/LearnImage.svg";
import Assign from "../../Assets/Sidebar/Assign.svg";
import Insight from "../../Assets/Sidebar/Insight.svg";
import AdminImage from "../../Assets/Sidebar/Admin.svg";
import FeedbackImage from "../../Assets/Sidebar/Feedback.svg";
import Polygon from "../../Assets/Sidebar/Polygon.svg";
import Contact from "../../Assets/Sidebar/Contact.svg";
import { IoIosLogOut } from "react-icons/io";
// import Logout from "../../Assets/Sidebar/Logout.svg";
import { LearnBox, learnText, sidebarComponent } from "../../Styles/Sidebar/Sidebar";
// import "../../App.css";
import "../../Styles/Sidebar/sideBar.css"

export default function SideBar() {
  const { userData } = useGlobalStateContext();
  const navigate = useNavigate();

  const sidebarText = [
    { image: learnIcon, text: "Learn", linkTo: "LearningPath", visible: true },
    { image: LearningPath, text: "Learning Path", linkTo: "LearningPath", visible: true },
    { image: Insight, text: "Insight", linkTo: "Insight", visible: true },
    { image: Assign, text: "Assign", linkTo: "Assign", visible: userData?.rights?.includes(3) },
    { image: AdminImage, text: "Admin Management", linkTo: "AdminManagement", visible: userData?.rights?.includes(3) },
    { image: FeedbackImage, text: "Feedback", linkTo: "Feedback", visible: true },
    { image: Contact, text: "Contact Us", linkTo: "Contact", visible: true },
  ];

  const [activeScenario, setActiveScenario] = useState(sessionStorage.getItem("activeScenario"));

  useEffect(() => {
    setActiveScenario(sidebarText[1].text);
  }, []);

  const handleClickBackground = (text) => {
    setActiveScenario(text);
    sessionStorage.setItem("activeScenario", text);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <>
      {userData && (
        <Box sx={sidebarComponent}>
          <Box
            sx={{
              width: "100%",
              background: "#000",
              // height: "calc(100vh - 6rem)",
              paddingBottom: "",
            }}
          >
            {sidebarText.map((item, index) => (
              <Link key={index} to={item.linkTo} style={{ textDecoration: "none", color: "inherit", display: item.visible ? "initial" : "none" }}>
                <Box
                  key={index}
                  onClick={() => {
                    handleClickBackground(item.text);
                  }}
                  sx={{
                    ...LearnBox,
                    background: activeScenario === item.text ? "#DA2128" : "black",
                    position: "relative",
                  }}
                >
                  <Box marginLeft={3}>
                    <img src={item.image} style={{ width: "30px", cursor: "pointer" }} alt="logo" />
                  </Box>
                  <Typography color={"white"} sx={learnText}>
                    {item.text}
                  </Typography>
                  {activeScenario === item.text && (
                    <img
                      src={Polygon}
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        position: "absolute",
                        right: "100%",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      alt="Polygon logo"
                    />
                  )}
                </Box>
              </Link>
            ))}
          </Box>

          <button onClick={handleLogout} className="side-bar-main-log-out-btn">
            <IoIosLogOut />
            Log Out
          </button>
        </Box>
      )}
    </>
  );
}
