import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  AlignItems,
  iconSearchMessage,
  ProfileText1,
  ProfileText2,
  imageProfileBox,
  // searchBox
} from "../../Styles/Navbar/navbar";
import Image from "../../Assets/CompanyLogo/Logo.svg";
import Message from "../../Assets/CompanyLogo/Message.svg";
import Notification from "../../Assets/CompanyLogo/notification.svg";
import profileImage from "../../Assets/placeHolder/profilePlaceHolder.png";
import SearchIcon from "@mui/icons-material/Search";
import { HiMenuAlt2 } from "react-icons/hi";
import { Typography } from "@mui/material";
import { useGlobalStateContext } from "../../states/GlobalState";
import Alert from "./Notification/Alert";
import "../../Styles/Navbar/Navbar.css"
import { useMobile } from "../MobileContext/MobileContext";

const Navbar = ({ setSidebarVisible }) => {
const { alert, setAlert, setSearchInput, courseSearch, userSearch, userData, workerSearch } = useGlobalStateContext()
const [searchText, setSearchText] = useState()
const [showAlert, setShowAlert ] = useState(false)
const {isMobile} = useMobile();

// useEffect(() => {
//   console.log(userData)
// }, [userData])

const vw = window.innerWidth

  useEffect(() => {
    let timer;
    if (alert) {
        setShowAlert(true);
        timer = setTimeout(() => {
            setShowAlert(false);
            setAlert(null)
        }, 3000);
    }
    return () => clearTimeout(timer);
  }, [alert, setAlert]);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value)
    setSearchText(e.target.value)
  }
  
  return (
    <>
      <div className="navbar-main-div">

        {(courseSearch || userSearch || courseSearch) && 
        searchText && 
          <div className="search-dropdown">
            {courseSearch && courseSearch.map((item) => (
              <div className="search-row">
                <p className="text course">
                  <span>Course:</span> {item.name}
                  </p>
                <p className="view-btn">View</p>
              </div>
            ))}
            {userSearch && userSearch.map((item) => (
              <div className="search-row">
                <p className="text user">
                  <span>User:</span> {item.full_name}
                  </p>
                <p className="view-btn">View</p>
              </div>
            ))}
            {workerSearch && workerSearch.map((item) => (
              <div className="search-row">
                <p className="text worker">
                  <span>Worker:</span> {item.name}
                </p>
                <p className="view-btn">View</p>
              </div>
            ))}
          </div>
        }

        <div className="logo-section">
          <Box component={"span"} height={"100%"} sx={AlignItems}>
            {" "}
            <img src={Image} style={{ width: "150px" }} alt="logo" />{" "}
          </Box>
          
         
        </div>

{!isMobile && 
        <div className="search-container"> 
          <input
            type="text"
            placeholder="Search..."
            className="navbar-search"
            onChange={(e) => handleSearchInput(e)}
          />

          <SearchIcon
            className="search-icon"
          />
        </div>
}


        <Box sx={iconSearchMessage}>
        {!isMobile &&     
          <Box sx={iconSearchMessage}>
            <Typography>
              {" "}
              <img src={Message} style={{ width: "25px",cursor:"pointer" }} alt="logo" />
            </Typography>
            <Typography marginLeft={5}>
              {" "}
              <img src={Notification} style={{ width: "25px" ,cursor:"pointer"}} alt="logo" />
            </Typography>
          </Box>
          }
          
          {userData &&
          <Box sx={iconSearchMessage}>

            <Typography color={"white"} sx={imageProfileBox}>    
              <img 
                src={userData.profile_picture || profileImage}
                style={{ width: "50px", cursor: "pointer" }} 
                alt="logo" 
                />
            </Typography>

            {!isMobile && 
            <Box color={"white"} sx={{ marginLeft: 2 }}>
              <Typography sx={ProfileText1}>{userData.first_name + " " + userData.last_name}</Typography>
              <Typography sx={ProfileText2} align="left">{userData.role}</Typography>
            </Box>
            }

            {/* <div className="drop-down-user-div">
              <button className="logout-btn">
                Log Out
              </button>
            </div>
           */}
          </Box>}

          {(vw<900) && <Box component={"span"} align="center" display="flex"
            onClick={() => setSidebarVisible(prev => !prev)} 
          >
            {" "}
            <HiMenuAlt2
              color="white"
              style={{ width: "40px", height: "40px" }}
            />
          </Box> }


        </Box>


        {showAlert && 
        <Alert message={alert} />}

      </div>
    </>
  );
};

export default Navbar;