import React from 'react'
import HeadingContent from "../../smallComponent/HeadingContent";
import UserDataCard from "../../smallComponent/LearningCard";
import profilePlaceHolder from "../../../Assets/placeHolder/profilePlaceHolder.png";
import roundRedAnimation from "../../../Assets/LearningPath/round-animation.svg";
import peopleIcon from "../../../Assets/icons/people.svg"
import pressentorIcon from "../../../Assets/icons/presentor.svg"
import clockyIcon from "../../../Assets/icons/clocky.svg"
import linePng from "../../../Assets/LearningPath/line.png"
import "../../../Styles/LearningPath/LearningPathMain.css";

const LearningPathMain = ({ userData, DataFeedback, contributionData }) => {
    const profileImage = userData.profile_picture || profilePlaceHolder
    const cardData = userData.assigned_courses;
    console.log(cardData)

  return (
    <>
        <div className="learning-path-main-div">
            <HeadingContent data={DataFeedback} />
            <div className="user-info">
                    <div className="image-container">
                        <img
                        src={profileImage}
                        className="profile-pic"
                        alt={"userName"}
                        />
                        <img
                        src={roundRedAnimation}
                        className="animated-circle"
                        alt="animation"
                        />
                    </div>
                <div
                    className="tag-div"
                >
                    <p className="label">
                        Name
                    </p>
                    <p className="value-text">
                        {userData.name}
                    </p>
                </div>

                <img src={linePng} className='line-png' alt='line' />

                <div className="tag-div">
                    <p className="label">
                    Designation
                    </p>
                    <p className="value-text">
                    {userData.designation}
                    </p>
                </div>
                <div className="tag-div" style={{ width: "220px" }}>
                    <p className="label">
                    Department
                    </p>

                    <p className="value-text">
                    {userData.department}
                    </p>
                </div>
                <div className="tag-div" style={{ width: "180px" }}>
                    <p className="label">
                    Location
                    </p>
                    <p className="value-text">
                    {userData.location}
                    </p>
                </div>
            </div>
        </div>

        <div className="contribution-div">
            <div className='main-data'>
                <p className='main-head'>{userData.name}'s Contribution</p>
            </div>
             
             <div className='main-data'>
                <img src={peopleIcon} className='icon' alt='icoon' />
                <p className='text'>
                    <span>{contributionData && contributionData.contribution.people_trained}</span>
                    People trained
                </p>
             </div>
             <div className='main-data'>
                <img src={pressentorIcon} className='icon' alt='icoon' />
                <p className='text'>
                    <span>{contributionData && contributionData.contribution.total_sessions}</span>
                    Number of sessions conducted
                </p>
             </div>
             <div className='main-data'>
                <img src={clockyIcon} className='icon' alt='icoon' />
                <p className='text'>
                    <span>{contributionData && contributionData.contribution.time_in_mins}</span>
                    Mins of training given
                </p>
             </div>
        </div>

        <div className='learning-card-container'>
            <div className='learning-card-div'>
                <UserDataCard 
                    title={"Mandatory"} 
                    cardData={cardData.mandatory} 
                />
            </div>
            <div className='learning-card-div'>
                <UserDataCard 
                    color={"#88FF95"} 
                    title={"Advised"} 
                    cardData={cardData.advised} 
                />
            </div>
            <div className='learning-card-div'>
                <UserDataCard 
                    color={"#4AB9FF"} 
                    title={"Other"} 
                    cardData={cardData.others} 
                />
            </div>
        </div>
      </>
  )
}

export default LearningPathMain