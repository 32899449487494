import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { GlobalStateProvider } from "./states/GlobalState";
import "./App.css";

//Pages Import Here 
import AdminManagement from "./component/AdminManagement/AdminManagement";
import Assign from "./component/Assign/Assign";
import Insights from "./component/Insights/Insights";
import LearningPath from "./component/LearningPath/LearningPath";
import Navbar from "./component/Navbar/Navbar";
import Contact from "./component/Pages/Contact";
import Feedback from "./component/Pages/FeedBack";
import SideBar from "./component/sidebar/SideBar";
import CreateAdmin from "./component/AdminManagement/AdminProfile/CreateAdmin";
import AssignModal from "./component/Assign/AssignModal/AssignModal";
import LoginPage from "./component/login/LoginPage";
import AdminCreated from "./component/AdminManagement/AdminProfile/AdminCreated";
import { MobileProvider } from "./component/MobileContext/MobileContext";

function ConditionalNavbar({ setSidebarVisible }) {
  const location = useLocation();

  const routesWithoutNavbar = [ "/" ];

  const isNavbarHidden = routesWithoutNavbar.includes(location.pathname);

  return !isNavbarHidden && <Navbar 
  setSidebarVisible = {setSidebarVisible}
  />;
}

function ConditionalSidebar() {
  const location = useLocation();

  const routesWithoutNavbar = [ "/" ];

  const isNavbarHidden = routesWithoutNavbar.includes(location.pathname);

  return !isNavbarHidden && <SideBar />;
}

function App() {

  const vw = window.innerWidth
  const [sidebarVisible, setSidebarVisible] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1100);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect (() => {
    if (isMobile) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }
  }, [isMobile])

  return (
  <Router>
    <div className="App">
      <GlobalStateProvider>
        <MobileProvider>
        <div className="navbar-app-container">
          <ConditionalNavbar 
          setSidebarVisible={setSidebarVisible}
          />
        </div>

        <div className="app-bottom-container">

          {sidebarVisible && <div className="sidebar-app-container">
              <ConditionalSidebar />
          </div> 
          }
         

          <div className="main-content-container">
            <Routes>
              <Route path="/" element={<LoginPage />} />
                {/* <Route path="/learningpath" element = {<LearningPath />} /> */}
                <Route path="/AdminManagement" element = {<AdminManagement />} />
                <Route path="/Admin/CreateAdmin" element = {<CreateAdmin />} />
                <Route path="/Admin/CreateAdmin/Admin" element = {<AdminCreated />} />
                <Route path="/Assign" element = {<Assign />} />
                <Route path="/Assign/Modal" element = {<AssignModal />} />
                <Route path="/Insight" element = {<Insights />} />
                <Route path="/LearningPath" element={<LearningPath />} />
                <Route path="/Contact" element = {<Contact />} />
                <Route path="/Feedback" element = {<Feedback />} />
            </Routes>
          </div>
        </div>
        </MobileProvider>
      </GlobalStateProvider>
    </div>
  </Router>
  );
}

export default App;
