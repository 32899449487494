import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import axiosInstance from "../../../interceptor/axiosInstance";
import peopleIcon from "../../../Assets/icons/people.svg"
import pressentorIcon from "../../../Assets/icons/presentor.svg"
import clockyIcon from "../../../Assets/icons/clocky.svg"
import placeholder from "../../../Assets/LearningPath/Girl.png"
import "../../../Styles/Insights/Contribution.css"

const Contribution = ({ setPopUpFormVisible, user }) => {
    const [ userData, setUserData] = useState();
    const [ username, setUsername ] = useState()

    useEffect(() => {
        axiosInstance
            .get(`analytics/training-received/${user}`)
            .then(res => {
                setUserData(res.data.data)
            })
            .catch(error => {
                console.log(error)
            })

        axiosInstance
            .get(`accounts/api/users/${user}/`)
            .then(res => {
                setUsername(res.data.data.full_name)
            })
            .catch(error => {
                console.log(error)
            })

    }, [user])
  
    return (
    <>
        {userData &&
        <div className='contribution-main-div'>
    
            <div 
                onClick={() => setPopUpFormVisible(false)}
                className='cross-icon'
            >
                <RxCross2 />
            </div>
        
            <p className='page-title'>{username}'s Contribution</p>

            <div className="contribution-div">
                
                <div className='main-data'>
                    <img src={peopleIcon} className='icon' alt='icoon' />
                    <p className='text'>
                        <span>{userData.contribution.people_trained}</span>
                        People trained
                    </p>
                </div>
                <div className='main-data'>
                    <img src={pressentorIcon} className='icon' alt='icoon' />
                    <p className='text'>
                        <span>{userData.contribution.total_sessions}</span>
                        Number of sessions conducted
                    </p>
                </div>
                <div className='main-data'>
                    <img src={clockyIcon} className='icon' alt='icoon' />
                    <p className='text'>
                        <span>{userData.contribution.time_in_mins}</span>
                        Mins of training given
                    </p>
                </div>
            </div>

            <p className='page-title'>Training Recieved</p>

            <table>
                <thead>
                    <th>Topic</th>
                    <th>Data</th>
                    <th>Location</th>
                    <th>Photos</th>
                </thead>
                <tbody>
                    {userData.training_received.map((item, index) => (
                    <tr key={index}>
                        <td>{item.course_name}</td>
                        <td>{item.date}</td>
                        <td>{item.location}</td>
                        <td><img src={placeholder} className="table-profile-image" alt="profile" /></td>
                    </tr>
                    ))}
                </tbody>
            </table>

        </div>}
    </>
    );
  };
  
  export default Contribution;