import React from 'react';
import RedButton from "../../globalComponents/redButton/RedButton";
import "../../Styles/Contact/ContactUs.css";

const ContactUsPage = ({
  userName,
  setUserName,
  setUserEmail,
  commentText,
  setCommentText,
  userPhNumber,
  setUserPhNumber,
  handleSubmit,
  submitLoader
}) => {
    const email = "dsslearningtrail@gmail.com"
    const phoneNumber = "+91 1234567890"
    
    const handleNameChange = (value) => {
    setUserName(value);
    };

    const handleEmailChange = (value) => {
    setUserEmail(value);
    };

    const handlePhNumberChange = (value) => {
    setUserPhNumber(value);
    };

    const handleCommentChange = (value) => {
    setCommentText(value);
    };

    return (
        <div className='contact-us-main-div'>

        <p className='page-title'>Contact Us</p>

        <div className='note'>
            You can contact us at <br/>
            <span>{email}</span>
            {" "}or{" "}
            <span>{phoneNumber}</span>
        </div>

        <p className='request-call'>
            Request a Call back
        </p>

        <input
            className='contact-us-input'
            placeholder='Full Name *'
            value={userName}
            onChange={(e) => handleNameChange(e.target.value)}
            required
        />

        <input
            className='contact-us-input'
            type='email'
            placeholder='Email'
            onChange={(e) => handleEmailChange(e.target.value)}
            required
        />

        <input
            className='contact-us-input'
            placeholder='Phone Number *'
            value={userPhNumber}
            onChange={(e) => handlePhNumberChange(e.target.value)}
            required
        />

        <textarea
            placeholder="Please enter your comments..."
            rows={5}
            maxLength={500}
            className='textarea'
            value={commentText}
            onChange={(e) => handleCommentChange(e.target.value)}
            required
        />

        <RedButton text={"Submit"} onClickHandler={handleSubmit} loading={submitLoader}/>

        </div>
    );
};

export default ContactUsPage;