import React, { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
// import { useGlobalStateContext } from "../../states/GlobalState";
import LearningPathMain from "./components/LearningPathMain";
import VerifyUser from "../../globalComponents/verifyUser/VerifyUser";
import "../../Styles/LearningPath/LearningPathMain.css";

export default function LearningPath() {
  // const { userId } = useGlobalStateContext()
  const [ userData, setUserData ] = useState()
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ name, setName ] = useState("")
  const [ sendUserId, setSendUserId ] = useState()
  const [ contributionData, setContributionData ] = useState()

  const pageTitle = "Learning Path"

  // useEffect(() => {
  //   console.log(userData)
  // }, [userData])

  const DataFeedback = {
    text: "Learning Path",
    textsmall:
      "Enhance your skills and ensure safety for yourself and your co-workers",
  };

  // const cardData = [
  //   {
  //     title: "Mandatory Courses",
  //     noOfCourses: 3,
  //     color: "#da2124",
  //     completed: [
  //       {
  //         title: "Chemical Handling",
  //         type: "assessment",
  //         progress: 50,
  //         refresherDate: "28 Sep 2023",
  //         certificate: null,
  //       },
  //       {
  //         title: "Fire Safety",
  //         type: "training",
  //         progress: 100,
  //         refresherDate: "28 Sep 2023",
  //         certificate: "samplePdf",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Advised Courses",
  //     noOfCourses: 3,
  //     color: "#88FF95",
  //     completed: [
  //       {
  //         title: "Emergency Preparedness",
  //         type: "assessment",
  //         progress: 0,
  //         refresherDate: "28 Sep 2023",
  //         certificate: null,
  //       },
  //       {
  //         title: "Hot Works",
  //         type: "training",
  //         progress: 0,
  //         refresherDate: "28 Sep 2023",
  //         certificate: null,
  //       },
  //       {
  //         title: "Slip, trip, falls",
  //         type: "checklist",
  //         progress: 0,
  //         certificate: null,
  //       },
  //     ],
  //   },
  //   {
  //     title: "Other Courses",
  //     noOfCourses: 3,
  //     color: "#4AB9FF",
  //     completed: [
  //       {
  //         title: "Machine Guarding",
  //         type: "assessment",
  //         progress: 0,
  //         refresherDate: "28 Sep 2023",
  //         certificate: null,
  //       },
  //       {
  //         title: "General Safety",
  //         type: "training",
  //         progress: 0,
  //         refresherDate: "28 Sep 2023",
  //         certificate: null,
  //       },
  //       {
  //         title: "Hand Safety",
  //         type: "checklist",
  //         progress: 0,
  //         certificate: null,
  //       },
  //     ],
  //   },
  // ];

  const retrieveUser = () => {
    if (sendUserId) {
      axiosInstance
      .get(`analytics/learning-path/${sendUserId}`)
      .then((res) => {
        const response = res.data.data
        setUserData(response)
        setCurrentPage(prev => prev + 1)
      })
      .catch(err => {
        console.error(err)
      })

      axiosInstance
        .get(`analytics/training-received/${sendUserId}`)
        .then((res) => {
            const response = res.data.data
            setContributionData(response)
        })
        .catch((error) => {
            console.error(error)
        })
    }
  }

  return (
    <div className="learning-path-main-container">
      {currentPage === 1 && 
        <VerifyUser 
          data={DataFeedback}
          pageTitle={pageTitle}
          postApiData={retrieveUser}
          name={name}
          setName={setName}
          sendUserId={sendUserId}
          setSendUserId={setSendUserId}

        />
      }
      {userData && currentPage === 2 &&
        <LearningPathMain
          userData={userData} 
          // cardData={cardData} 
          DataFeedback={DataFeedback}
          contributionData={contributionData}
        />
      }
    </div>
  );
}
