import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import axios from 'axios';
import Login from './Login';
import { useGlobalStateContext } from '../../states/GlobalState';

const LoginPage = () => {
    const navigate = useNavigate();
    const { setUserData } = useGlobalStateContext()
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginInfo, setLoginInfo] = useState({});
    const [validationError, setValidationError] = useState(null);
    const [ alertText, setAlertText ] = useState()
    const [submitLoader, setSubmitLoader] = useState(false)
    const [ showPopUp, setShowPopUp ] = useState(false)

    const heading = "Welcome"
    const heroText = "Enhance your skills and ensure safety for yourself and your co-workers";
    const forgotText = "Forgot Password? "

    // const secretKey = "hag diya hag diya hag diya"

    // Decrypt tokens when retrieving
    // const decryptedAccess = CryptoJS.AES.decrypt(localStorage.getItem('access'), 'hag diya hag diya hag diya').toString(CryptoJS.enc.Utf8);
    // const decryptedRefresh = CryptoJS.AES.decrypt(localStorage.getItem('refresh'), 'hag diya hag diya hag diya').toString(CryptoJS.enc.Utf8);


    const loginSchema = z.object({
        email: z.string().email(),
        password: z.string().min(8),
    });

    useEffect(() => {
        sessionStorage.clear()
    }, [])

    useEffect(() => {
        if (validationError) {
          setAlertText(`Invalid ${validationError[0].path[0]}`)
        }
    }, [validationError])

    useEffect(() => {
        let timer;
        if (alertText) {
            timer = setTimeout(() => {
                setAlertText(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [alertText]);

    useEffect(() => {
        setLoginInfo({
            email: userEmail,
            password: password,
        });
    }, [userEmail, password]);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      }

    const handleSubmit = () => {
        setSubmitLoader(true)
        try {
            loginSchema.parse(loginInfo);
            axios
                .post('https://api.learningtrailsdemo.com/login', loginInfo)
                .then((res) => {
                    const response = res.data.data
                    setSubmitLoader(false)
                    res.status === 200 && navigate("/LearningPath")
                    setUserData(response.user)
                    localStorage.setItem('access', response.access);
                    localStorage.setItem('refresh', response.refresh);
                })
                .catch((error) => {
                    setSubmitLoader(false)
                    error && setAlertText("Invalid User ID or Password")
                });
        } catch (error) {
            scrollToTop()
            setSubmitLoader(false)
            // Handle validation error
            setValidationError(error.errors);
        }
    };

    return (
        <>
            <Login
                heading={heading}
                heroText={heroText}
                forgotText={forgotText}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                password={password}
                setPassword={setPassword}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                handleSubmit={handleSubmit}
                validationError={validationError}
                submitLoader={submitLoader}
                alertText={alertText}
                showPopUp={showPopUp}
                setShowPopUp={setShowPopUp}
            />
        </>
    );
};

export default LoginPage;