export const SmallCards = {
    width: "22%",
    borderRadius: "18.918px", // corrected from border-radius

    display:"flex",
    
    flexDirection:"column",
  padding:1,
    height: "175.446px"
};

export const text1 = {
    color: "#FFF",
    textAlign: "center",
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "14.995px",
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: "82%",
    marginTop:2 /* 13.936px */
};

export const text2 = {
    color: "#FFF",
    textAlign: "center",
    textShadow: "1.7px 1.7px 30.592px rgba(0, 0, 0, 0.25)",
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "50px",
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: "82%", /* 41px */
    textTransform: "uppercase",
    marginTop:2 
};

export const text3 = {
    color: "#FFF",
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "13.642px",
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: "82%",
    marginTop:2  /* 12.007px */
};

export const text4 = {
    color: "#FFF",
    display:"flex",
    alignItems: "center",
    justifyContent:"center" ,
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "13.16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "82%",
    marginTop:2  /* 10.791px */
};
