export const modalContainer={
    height: "100%",
    width: "80%",
    position: "fixed",
    zIndex: "999",
    top: "6rem",
    left: "20%",
    backgroundColor: "transparent",
    index:1,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingBottom:"4rem"
}
export const closeBtn = {
  position: "absolute",
  top: "1.5rem",
  right: "1.5rem",

  width: "2.5rem",
  aspectRatio: "1/1",
  borderRadius: "1.5rem",
  backgroundColor: "#454545",
  overflow: "hidden",
  cursor: "pointer",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  color: "#fff",
  fontSize: "2rem",
}

export const modalBox = {
    width: '76%',
    height: '60%',
    flexShrink: 0, // Note: Use camelCase for CSS properties in JavaScript objects
    borderRadius: '59px',
    border: '2px solid #FFF',
    background: 'rgba(0, 0, 0, 0.50)',
    padding:"2rem 4rem",
    backgroundColor: "#000",
    position: "relative",
    overflow: "auto",
    BoxSizing: "border-box",
  // Custom scrollbar styles for WebKit browsers
  "::-webkit-scrollbar": {
    width: '10px', // Width of the scrollbar
    backgroundColor: 'rgba(0, 0, 0, 0.50)', // Background color of the scrollbar
    borderRadius: '10px', // Border radius of the scrollbar
},
"::-webkit-scrollbar-thumb": {
    backgroundColor: '#FFF', // Color of the scrollbar thumb
    borderRadius: '10px', // Border radius of the scrollbar thumb
}
}
  export const textmodal = {
    color: '#FFF',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '87.214px', // 181.695%
    textTransform: "capitalize"

  };
  export const formRow={
    display:"flex",
    flexDirection:"row",
    gap: '10px',
    justifyContent:"center",
    alignItems:"center",
  }
  export const BoderCSS={
    boderBottom:"2px solid #fff",
    marginBottom:"1rem"
  }
  export const formColumn={
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    marginRight:"1rem"
  }
  export const contentText = {
    color: '#FFF',
    textAlign: 'left',
    textShadow: '0px 4.362px 4.362px rgba(0, 0, 0, 0.25)',
    fontSize: '25px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '22px',
  
  };
  export const formChild = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
    color: '#464646',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 350,
    lineHeight: '135.8%',
  /* Adjusted the value, use '135.8%' or '16.296px' depending on your preference */
  };
  export const buttonReset={
    borderRadius: "61.111px",
    border:" 1.111px solid #DA2128",
    background: "transparent",
    color: "#FFF",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    width: "130px",
    height: "36px",
    marginRight:"1rem"
  }