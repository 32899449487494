
export const AdminContainerBox={
    minHeight: "100vh",
    width: "80%",
    position: "absolute",
    top: "6rem",
    left: "20%",
    backgroundColor: "#1F1F1F",
    index:1,
    
}
export const AdminButtonBox={
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    marginLeft :"3rem",
    marginRight:"3rem"

}
export const SelectGroup={
    display:"flex",
    flexDirection:"row",
   gap:"1rem"

}
export const buttonbg = {
    borderRadius: "50.111px",
    border: "1px solid #DA2128",
    background: "transparent",
    boxShadow: "0px 4.444px 4.444px 0px rgba(0, 0, 0, 0.25)",
    color: "#FFF",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26.25px",
    width: "255px",
    height: "35px",
    cursor: "pointer",
    ":hover": {
      background: "#DA2128",
    },
  };
  
 
  export const ProfileText1 = {
    color: '#FFF', // Make sure to enclose the color value in quotes
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: '17.5px',
    fontStyle: 'normal',
    fontWeight: 600,
    textTransform: "capitalize",
    lineHeight: '17.5px', // Make sure to enclose the value in quotes
  };
  export const ProfileText2 = {
    color: '#9D9FA2',
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: '14.444px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '143.8%', /* 20.771px */
    letterSpacing: '0.433px',
    textTransform: "capitalize",
  };
  
  export const imageProfileBox={
    marginLeft:5,
    paddingLeft:3,

  }  
  export const iconSearchMessage={
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft:"-40px"
  }

  export const tableBox = {
    
    borderRadius: "20px",
    padding: "20px",
    marginTop: "20px",
    marginLeft :"3rem",
    marginRight:"3rem"

 
  };
  export const cellBody = {
    color: "#FFF", // Color value should be a string and enclosed in quotes
    boxSizing: 'border-box',
    textDecoration: 'none', // Font family should be a string and enclosed in quotes
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26.25px", // Line height value should be a string and enclosed in quotes
    textTransform: "uppercase", // Property names in camelCase
  };
  export const  tableStyle ={
  
        width: "100%",
        borderCollapse: "collapse",
        border: "1px solid #464646", // Set the border color for the entire table
        color: "#FFF",
        paddingTop:"40px"
   
  }
  export const bodyBox={
    padding:"5px"
  }
  export const bodyCell={
    borderBottom: "1px solid #464646",
    padding:"20px",
    alignItems:"left",
    textAlign:"left"
  }
  export const tableData = {
    color: '#989898',
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 350,
    lineHeight: '120%',
   display:"flex",
   textAlign:"center",
   justifyContent:"center",
   alignItems:"center",
    height: '39px',
    flexShrink: 0, // Use camelCase for CSS property names in JavaScript
    borderRight: '1px solid #282828',
    borderLeft: '1px solid #282828',
    background: '#282828',
  };
  export const modifyText = {
    color: '#FFF',
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginRight:3,
  };
  export const modifyDisplay={
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-around"
  }
  export const belowTableTBox={
    height: '39px',
    flexShrink: 0, // Use camelCase for CSS property names in JavaScript
    borderRight: '1px solid #282828',
    borderLeft: '1px solid #282828',
  
  }