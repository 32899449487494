export const CreateAdminContainerBox={
    minHeight: "100vh",
    width: "80%",
    position: "absolute",
    top: "6rem",
    left: "20%",
    backgroundColor: "#1F1F1F",
    index:1,
    paddingBottom:"5rem"
    
}
export const adminText = {
    color: '#FFF',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '43.75px', 
    marginLeft:"3rem" /* 168.269% */
  };
  export const lineSpan={
    width: '75%', borderTop: '1px dotted grey', display: 'inline-block',marginTop:3,marginLeft:1
  }
  
  export const textAdminBox={
    display:"flex",
    flexDirection:"row",
    marginBottom:"2rem"
  }
  export const lineSpans={
    width: '70px', borderBottom: '1px solid grey', display: 'inline-block',marginBottom:5
  }
  export const lineSpantext={
    color: 'grey', margin: '0 10px', display: 'inline-block'
  }
  export const ORBOx={
marginLeft:"1rem",
marginTop:1
  }
  export const inputFeedback = {
    borderRadius: '77.143px',
    border: '1.429px solid #4C4C4C',
    width: '150px',
    height: '20px',
    flexShrink: 0,
    color: '#6E6E6E',
    fontSize: '15px',
    padding:"10px",
    fontStyle: 'normal',
    background:"transparent",
    fontWeight: 550,
    lineHeight: '82%',
    marginLeft:3,
    marginTop:5 /* 16.4px */
  };
  export const buttonbg = {
    border: "1.429px solid #4C4C4C",
    borderRadius:"30px",
    fill: '#9D9FA2',
    width: '121px',
    height: '30.25px',
    flexShrink: 0,
    color: '#9D9FA2',
    boderColor:"1px solid grey",
    background:"transparent",
    fontSize: '14.638px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '105.75%', /* 17.594px */
  };
  export const FormBox={
    display: 'grid', gridTemplateColumns: 'auto auto auto', gridGap: '10px' ,marginLeft:"2rem"
  }
  
  export const inputDetailBox={
    display:"flex",
    flexDirection:"row",
  
    marginTop:"1rem"
  }
  export const inputDetailText = {
    color: '#FFF', // Enclosed color value in quotes
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '43.75px',
    marginRight:"2rem" /* 182.292% */
  };
  export const labelBox={
    color: 'white',
    boxSizing: 'border-box',
    textDecoration: 'none',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '43.75px',
        marginRight:"3rem" 
  }
  export const inputBox = {
    width: '20.25px',
    height: '20.25px',
    marginRight: '5px',
    background: 'transparent', // Set background color to transparent
    border: '1px solid white', // Set the checkbox border to solid 1px white
  };
  export const plantext = {
    width: '137px', // Enclosed width value in quotes
    height: '41px', // Enclosed height value in quotes
    flexShrink: 0,
    borderRadius: '77.143px', // Enclosed border-radius value in quotes
    border: '1.429px solid #4C4C4C',
    background: '#171717',
    color: '#9D9FA2',
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '82%', 
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    
  };
  export const accesContainer={
    paddingBottom:"3rem",
    borderBottom:"1px solid grey",
    marginLeft:"3rem"
  }