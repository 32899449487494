import React, { useEffect, useState } from 'react';
import "../../../Styles/globalComponent/SinglePersonForm.css";

const SinglePersonForm = ({ title, setName, name, postApiData, userList, setMessage, userId, setUserId, sendUserId, setSendUserId }) => {
  const [ dropdownList, setDropdownList ] = useState([])
  const [ isDropdownVisible, setIsDropdownVisible ] = useState(false)

  useEffect(() => {
    if(userList && name) {
      const userArray = userList.filter((item) => {
              const fullname = item.full_name.toLowerCase();
              const inputName = name.toLowerCase();

              return inputName && fullname.startsWith(inputName) && inputName !== fullname
            })
            .slice(0, 3)

    setDropdownList(userArray)
  } else if (userList) {
    const sortedList = userList.sort((a, b) => {
      const nameA = a.full_name.toUpperCase();
      const nameB = b.full_name.toUpperCase();
  
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
  });

    setDropdownList(sortedList.slice(0, 3))
  }
  }, [userList, name])

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setName(inputValue);
  };

  const handleSubmit = () => {
    const isValid = userList.find((item) => item.id === sendUserId)

    if (isValid && name) {
      postApiData()
    } else {
      alert(name ? "User does not exist" : "Please enter your name");
    }
  };

  const handleDropdownSelect = (id, name) => {
    setSendUserId(id)
    setName(name)
    setIsDropdownVisible(false)
  }

  return (
    <div className='single-person-form-main-div'>
      <p className='input-label'>
        You need to submit a name below to proceed with
        <span> {title}</span>
      </p>

    <div 
      className='input-container'
    >
      <input
        type='text'
        className="input-box"
        placeholder='Please enter the name here...'
        value={name}
        onChange={handleInputChange}
        onFocus={() => setIsDropdownVisible(true)}
      />

    {isDropdownVisible &&
    <div className='dropdown-learning-container'>
      {dropdownList.map((item, id) => (
        <div 
          onClick={() => handleDropdownSelect(item.id, item.full_name)} 
          className='dropdown-row' 
          key={id}
        >
          <p className='text-name'>{item.full_name}</p>
        </div>
      ))
      }
      </div>}
    </div>

      <button className='submit-btn' onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default SinglePersonForm;
