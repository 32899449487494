import React from 'react';
import { SmallCards, text1, text2, text3, text4 } from '../../../Styles/Insights/smallCard';
import { Box, Typography } from '@mui/material';
import axiosInstance from '../../../interceptor/axiosInstance';
import { saveAs } from 'file-saver';

const SmallCard = ({ data, reportUrl }) => {

  const handleDownload = () => {
    if (reportUrl) {
      axiosInstance
        .get(reportUrl, { responseType: 'blob' }) // Specify responseType as 'blob' to get binary data
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'report.xlsx'); // Save the blob as an .xlsx file
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <>
      <Box sx={{ ...SmallCards, background: data.color }}>
        <Typography sx={text1}>{data?.text1}</Typography>
        <Typography sx={text2}>{data?.text2}</Typography>
        {data?.text3 && (
          <Typography sx={text3}>
            <img src={data?.text3Icon} alt="Eye Icon" style={{ width: "20px" }} />
            {data?.text3}
          </Typography>
        )}
        <Typography sx={{ ...text4, cursor: "pointer" }} onClick={handleDownload}>
          <img src={data?.text4Icon} alt="Eye Icon" style={{ width: "20px" }} />
          {data?.text4}
        </Typography>
      </Box>
    </>
  );
}

export default SmallCard;
