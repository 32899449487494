

export const insightContainer={
    minHeight: "100%",
    width: "100%",
    boxSizing: "border-box",
}
export const selectBox={
    paddingInline: "4rem",
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems: "center",
    boxSizing: "border-box",
    gap: "2rem"
}
export const displayCardBox={
    display:"flex",
    flexDirection:"column",
    // marginLeft:5,
    paddingInline: "4rem",
    boxSizing: "border-box",
    width: "100%",
    // minWidth:"80%",
    marginTop:5
}
export const trainingSessionBox = {
    width: "100%",
    borderRadius: "46.988px",
    background: "linear-gradient(112deg, #DA2129 0.14%, #7F1418 105.05%), #DA2128",
    display:"flex",
    flexDirection:"row",
    height: "161px",
    justifyContent:"space-around",
    itemAlign:"center"
};
 
export const trainingText = {
    textAlign: "left", // or "start" if you prefer
    display: "flex",
    alignItems: "center", // corrected from itemAlign
    width: "25%",
    color: "#FFF",
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: "111%", /* Use quotation marks for percentage values */
};

export const numberText = {
    color: "#FFF", /* Enclose color value in quotation marks */
    alignItems: "center", 
    display:"flex",
    textAlign: "center",
    textShadow: "2.549px 2.549px 45.882px rgba(0, 0, 0, 0.25)",
    fontSize: "80px", /* Use quotation marks and provide appropriate units */
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: "82%", /* Use quotation marks for percentage values */
};
export const lastText = {
    color: "#FFF",
    alignItems: "center", // Align items along the cross-axis
    display: "flex",
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: "105.5%",
    justifyContent: "center", // Align items along the main axis
};


export const lastDownloadTexts = {
    color: "#FFF",
    display:"flex",
    alignItems: "center", 
    cursor: "pointer",
    
    textAlign:"start",
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: "20.093px", 
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "82%", 
};
export const displayBoxcd={
    display:"flex",
    alignItems: "center", 
    justifyContent:"center",
}
export const smallBoxCOntainer={
    marginTop:5,
    marginBottom:5,
    width:"90%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
}
export   const buttonbg = {
    borderRadius: "61.111px",
    border:"1.108px solid #676767",
    background: "transparent",
    boxShadow: "0px 4.444px 4.444px 0px rgba(0, 0, 0, 0.25)",
    color: "#FFF",
    boxSizing: 'border-box',
    textDecoration: 'none',    
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "75%",
    width: "200px",
    height: "46px",
  };


  export const swiperContainerBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Corrected spelling of alignItems
    // Add other styles as needed
  };
  
  export const SwiperTextBox={
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around",
    marginLeft:"3rem",
    marginTop:"3rem"
  }
  export const swiperHeadder = {
    color: '#FFF', // Enclose color value in quotation marks
    boxSizing: 'border-box',
    textDecoration: 'none',    fontSize: '20px', // Use quotation marks and provide appropriate units
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '43.75px', 
    itemAlign:"right"// Use quotation marks for percentage values
    // Add other styles as needed
  };
  export const swiperHeadderBodder = {
    width: "600px",
    height:"2px",   
    borderBottom: "2px dotted white",
    marginTop:"1.2rem",
    marginLeft:2 // Use "dotted" for a dotted border
    // Add other styles as needed
  };
  
  export const ContentBOX={
    // marginTop:"6rem",
    marginBottom:"6rem",
    // marginLeft:"3rem",
    // marginRight:"2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent:"space-around",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    boxSizing: "border-box",
    // paddingInline: "1rem",
  }