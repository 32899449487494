export const selectStyles = {
    color: '#FFF',
    textAlign: 'center',
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: '17px',
    padding:"5px",
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '82%', // Adjusted to a string with a unit
    borderRadius: '77.143px',
    border: '2px solid #f6f6f6',
    backgroundColor:"transparent",
   
  };
  export const optionBg = {
    backgroundColor: "#1F1F1F",
    borderBottom: "4px solid white",
    padding:2,
    color: "#FFF",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "135.8%", /* 17.654px */
    textTransform: "uppercase",
    alignItems: "start", // Corrected property name
  };
  
  
  