import React, { useEffect, useState }  from 'react'
import {
    CreateAdminContainerBox,
    adminText,
    lineSpan,
    textAdminBox,
    buttonbg,
  } from "../../../Styles/AdminManagement/CreateAdminStyle";
import { ThreeDots } from 'react-loader-spinner'
import { useLocation } from "react-router-dom"
import { Box, Typography } from "@mui/material";
import { useGlobalStateContext } from '../../../states/GlobalState';
import { Link } from 'react-router-dom';
import HeadingContent from "../../smallComponent/HeadingContent";
import Arrow from "../../../Assets/icons/Arrow.svg";
import Accessibility from './Accessibility';
import ProfilePage from '../../smallComponent/ProfilePage';
import axiosInstance from '../../../interceptor/axiosInstance';
import RedButton from '../../../globalComponents/redButton/RedButton';

 const AdminCreated = () => {
  const location = useLocation();
  const responseData = location.state?.response;
  const { plantList, rightsList } = useGlobalStateContext()
  const [ userData, setUserData ] = useState()
  const [ selectedRights, setSelectedRights ] = useState([])
  const [ plantAccess, setPlantAccess ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)

  const DataFeedback = { text: " Create an Admin " }

  useEffect(() => {
    axiosInstance
      .get(`accounts/api/users/${responseData}/`)
      .then((res) => {
        const response = res.data.data
        setUserData(response)
        setSelectedRights(response.rights)
        setPlantAccess(response.plant_access)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [responseData])

  const handleClick = () => {
    setIsLoading(true)
    const data = {
      rights: selectedRights.join(","),
      plants: plantAccess.join(",")
    }
    console.log(data)

    axiosInstance
      .patch(`accounts/api/users/${responseData}/`, data)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
   {userData &&
   <Box sx={CreateAdminContainerBox}> 
      <HeadingContent data={DataFeedback} />
        <Box sx={textAdminBox}>
          {" "}
          <Typography component={"span"} sx={adminText}>
            Admin Details
          </Typography>
          <Typography component={"span"} sx={lineSpan}></Typography>
        </Box>
        
        <Box>
          <ProfilePage 
            profile={userData.profile_picture}
            name={userData.full_name}
            department={userData.department}
            designation={userData.designation}
            location={userData.location}
          />
        </Box>

        <Box sx={textAdminBox}>
          {" "}
          <Typography component={"span"} sx={adminText}>
            Accessibility
          </Typography>
          <Typography
            component={"span"}
            sx={{ ...lineSpan, marginLeft: "10px" }}
          >

          </Typography>
        </Box>

      <Box>
        <Accessibility 
          userData={userData} 
          plantList={plantList}
          rightsList={rightsList}
          setSelectedRights={setSelectedRights}
          selectedRights={selectedRights}
          plantAccess={plantAccess}
          setPlantAccess={setPlantAccess}
        />
      </Box>

      <Box align="left" marginTop="1rem" marginLeft="3rem" marginBottom="1rem">
        <RedButton
          text="Submit"
          onClickHandler={handleClick}
          loading={isLoading}
        />  
      </Box>

      <Box align="left" marginLeft={"3rem"}>
        <Link to={"/AdminManagement"} style={{ textDecoration: 'none', color: 'inherit' }}>

        <button style={{ ...buttonbg }}>
          <span style={{ color: "#DA2128", paddingRight: "4px" }}>
            <img src={Arrow} alt="nothing" />
          </span>
          Go Back{" "}
        </button>
        </Link>
      </Box>
   </Box>}

   </>
  )
}
export default AdminCreated