import React, { useEffect, useState } from "react";
import {
  CreateAdminContainerBox,
  adminText,
  lineSpan,
  textAdminBox,
  inputFeedback,
  lineSpantext,
  lineSpans,
  ORBOx,
  buttonbg,
  FormBox,
} from "../../../Styles/AdminManagement/CreateAdminStyle";
import { selectStyles, optionBg } from "../../../Styles/HeadingStyle/InputSelectStyle";
import { Box, Typography } from "@mui/material";
import HeadingContent from "../../smallComponent/HeadingContent";
// import InputSelect from "../../smallComponent/InputSelect";
import MainButton from "../../smallComponent/MainButton";
import Arrow from "../../../Assets/icons/Arrow.svg";
import ProfilePage from "../../smallComponent/ProfilePage";
// import Accessibility from "./Accessibility";
import { Link } from "react-router-dom";
import axiosInstance from "../../../interceptor/axiosInstance";
import RedButton from "../../../globalComponents/redButton/RedButton";

function CreateAdmin() {
  const [open, setOpen] = useState(false);
  const [ userList, setUserList ] = useState([])
  const [ selectedValue, setSelectedValue ] = useState()
  const [ loading, setLoading ] = useState(true)
  const [ firstName, setFirstName ] = useState("")
  const [ lastName, setLastName ] = useState("")
  const [ designation, setDesignation ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ selectedDepartment, setSelectedDepartment ] = useState()
  const [ userId, setUserId ] = useState()
  const [ userData, setUserData ] = useState(null)
  const [ apiChanges, setApiChanges ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const DataFeedback = {
    text: " Create an Admin ",
  };

  useEffect(() => {
    if (userData) {
      setApiChanges({
        AdminManagement: (userData.rights.includes(1)),
        AssignManagement: (userData.rights.includes(2)),
      })
    }
  }, [ userData])

  useEffect(() => {
    console.log(selectedValue)
  }, [selectedValue])

  useEffect(() => {
    if (userList) {
      setLoading(false)
    }
  }, [userList])

  const department = [
    "operations",
    "development",
    "design"
  ]

  useEffect(() => {
    axiosInstance
      .get("accounts/api/users")
      .then((res) => {
        const response = res.data.data
        setUserList(response)
      })
  }, [])

  useEffect(() => {
    if (userId) {
      axiosInstance
        .get(`accounts/api/users/${userId}/`)
        .then(res => {
          setUserData(res.data.data)
          console.log(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [userId])

  const handleClickOpen = () => {
    setIsLoading(true)
    setOpen(true)
    const adminDetail = { role: "admin"}

    const createUser = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      designation: designation,
      department: selectedDepartment,
    }

    if (firstName && lastName && designation && email && selectedDepartment) {
      axiosInstance
      .post("accounts/api/createUser/", createUser)
      .then((res) => {
        console.log(res.data.data.id)
        setUserId(res.data.data.id)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false))

      return
    } 

    if (selectedValue) {
      axiosInstance
        .patch(`accounts/api/users/${selectedValue}/`, adminDetail)
        .then((res) => {
          console.log(res)

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => setIsLoading(false))

        return
    }

    if (!(firstName && lastName && designation && email && selectedDepartment && selectedValue)) {
      alert("please select an input")
    }

    setIsLoading(false)
  };

  const handleDepartmentSelect = (event) => {
    const { value } = event.target;
    setSelectedDepartment(value);
  }

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
  };

  const handleClick = () => {
    if (apiChanges) {
      const data = {
        ...(apiChanges.AdminManagement && {rights: 2}),
        ...(apiChanges.AssignManagement && {rights: 1}),
        ...(apiChanges.AssignManagement && apiChanges.AdminManagement && {rights: "1,2"}),
      }
      console.log(data)

      axiosInstance
        .patch(`accounts/api/users/${userId}/`, data)
        .catch((error) => {
          console.error(error)
        })
    } 
  }

  return (
    <Box sx={CreateAdminContainerBox}>

    {!loading &&
    <>
      <HeadingContent data={DataFeedback} />

      <Box sx={textAdminBox}>
        {" "}
        <Typography component={"span"} sx={adminText}>
          Admin Details
        </Typography>
        <Typography component={"span"} sx={lineSpan}></Typography>
      </Box>

      <Box sx={textAdminBox}>
        <Box sx={{ ...textAdminBox, marginLeft: "3rem", marginTop: "3rem" }}>
          {/* <InputSelect
            // data={userList}
            name="Select_Employee"
            selectedValue={selectedValues.SortByAccess}
            onSelectChange={handleSelectChange}
            onChange={handleChange}
          /> */}
          <div>
            <select value={selectedValue} style={{ ...selectStyles }} onChange={handleChange}>
              <option style={{ ...optionBg }} value="">User List</option>
              {userList.map((item, index) => (
                <option style={{ ...optionBg }} key={index} value={item.id}>
                  {item.full_name}
                </option>
              ))}
            </select>
          </div>
          <Box sx={ORBOx}>
            <span style={{ ...lineSpans }}></span>
            <span style={{ ...lineSpantext }}>OR</span>
            <span style={{ ...lineSpans }}></span>
          </Box>{" "}
        </Box>


        <Box style={FormBox}>

          <input
            style={{
              ...inputFeedback, // Add padding as needed
              // Add any additional styles you need
            }}
            type="text" // You can adjust the input type as needed
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />{" "}

          <input
            style={{
              ...inputFeedback, // Add padding as needed
              // Add any additional styles you need
            }}
            type="text" // You can adjust the input type as needed
            placeholder="Designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />{" "}

          <Box marginTop={1}>

            {/* <InputSelect
              data={selectData?.Country}
              name="Department"
              // selectedValue={selectedValues.SortByAccess}
              // onSelectChange={handleSelectChange}
            /> */}

            <div>
              <select value={selectedDepartment} style={{ ...selectStyles }} onChange={handleDepartmentSelect}>
                <option style={{ ...optionBg }} value="">Department</option>
                {department.map((item, index) => (
                  <option style={{ ...optionBg }} key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

          </Box>

          <input
            style={{
              ...inputFeedback, // Add padding as needed
              marginTop: "0.7rem"
              // Add any additional styles you need
            }}
            type="text" // You can adjust the input type as needed
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />{" "}

          <input
            style={{
              ...inputFeedback, // Add padding as needed
              marginTop:".6rem"// Add any additional styles you need
            }}
            type="text" // You can adjust the input type as needed
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />{" "}

          {/* <Typography marginTop={".7rem"} onClick={() => {
            handleClickOpen();
          }}> */}

          <RedButton
            text="Submit"
            onClickHandler={handleClickOpen}
            loading={isLoading}
          />

          {/* </Typography> */}
     
        </Box>
      </Box>

      <Box align="left" marginLeft={"3rem"}>
      <Link to={"/AdminManagement"} style={{ textDecoration: 'none', color: 'inherit' }}>

      <button style={{ ...buttonbg }}>
          <span style={{ color: "#DA2128", paddingRight: "4px" }}>
            <img src={Arrow} alt="nothing" />
          </span>
          Go Back{" "}
        </button>
        </Link>
      </Box>

      {open && userData && 
        <Box sx={{ marginTop: "2rem" }}>
          <Box sx={textAdminBox}>
            {" "}
            <Typography component={"span"} sx={adminText}>
              Accessibility
            </Typography>
            <Typography
              component={"span"}
              sx={{ ...lineSpan, marginLeft: "10px" }}
            ></Typography>
          </Box>
          
          <Box>
            <ProfilePage 
              name={userData.full_name} 
              designation={userData.designation}
              department={userData.department}
              location={userData.location}
            />
          </Box>

          {/* <Box>
            <Accessibility userData={userData} apiChanges={apiChanges} setApiChanges={setApiChanges}/>
          </Box> */}

          {/* <Box align="left" marginLeft={"3rem"} marginTop={"2rem"}>
            <MainButton onClick={handleClick} name="Save & Submit" width="215px" height="46px" />
          </Box> */}
          <Box align="left" marginLeft={"3rem"} marginTop={"2rem"}>
         
            <button style={{ ...buttonbg }}>
              <span style={{ color: "#DA2128", paddingRight: "4px" }}>
                <img src={Arrow} alt="nothing" />
              </span>
              Go Back{" "}
            </button>
          </Box>
        </Box>}

      </>}

    </Box>
  );
}

export default CreateAdmin;