import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Styles/Insights/insights.css";
import { Box, Typography } from "@mui/material";
import { IoSearch } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { FaCalendar } from "react-icons/fa";
import {
  insightContainer,
  smallBoxCOntainer,
  lastText,
  selectBox,
  displayBoxcd,
  displayCardBox,
  buttonbg,
  numberText,
  trainingSessionBox,
  trainingText,
  lastDownloadTexts,
  SwiperTextBox,
  swiperHeadder,
  swiperHeadderBodder,
  ContentBOX,
} from "../../Styles/Insights/InsightStyle.js";
import HeadingContent from "../smallComponent/HeadingContent";
import Arrowss from "../../Assets/Insight/arrow.svg";
import SwiperInsight from "./swiper/SwiperInsight.js";
import DownloadIcon from "../../Assets/Insight/downloadIcon.svg";
import RightLeft from "../../Assets/Insight/RightLeft.svg";
import ContentBox from "./ContentBox/ContentBox.js";
import SmallCard from "../smallComponent/Insight/SmallCard.js";
import axiosInstance from "../../interceptor/axiosInstance";
import { selectStyles } from "../../Styles/HeadingStyle/InputSelectStyle";
import "../../Styles/Insights/insights.css"
import Contribution from "./popUp/Contribution";
import { useGlobalStateContext } from "../../states/GlobalState";

export default function Insights() {
  const { plantList, countriesList, stateList } = useGlobalStateContext()
  const [ insightsData, setInsightsData] = useState()
  const [ facilitatorData, setFacilitatorData ] = useState()
  const [ employeesData, setEmployeesData ] = useState()
  const [ selectedPlant, setSelectedPlant ] = useState([])
  const [ selectedCountry, setselectedCountry ] = useState([])
  const [ selectedState, setSelectedState ] = useState([])
  const [ showDropDown, setShowDropDown ] = useState(false)
  const [ searchInput, setSearchInput ] = useState("")
  const [ userList, setUserList ] = useState()
  const [ filteredUser, setFilteredUser ] = useState()
  const [ selectedUser, setSelectedUser ] = useState()
  const [ popUpFormVisible, setPopUpFormVisible ] = useState(false)
  const [ startDate, setStartDate ] = useState()
  const [ endDate, setEndDate ] = useState()
  const [ startDateFacilitator, setStartDateFacilitator ] = useState()
  const [ endDateFacilitator, setEndDateFacilitator ] = useState()
  const [ contentCards, setContentCards ] = useState()
  const [ selectedPlantFacilitator, setSelectedPlantFacilitator ] = useState([])
  const [ selectedPlantEmployees, setSelectedPlantEmployees ] = useState([])
  const [ selectedCountryFacilitator, setselectedCountryFacilitator ] = useState([])
  const [ selectedStateFacilitator, setSelectedStateFacilitator ] = useState([])

  useEffect(() => {
    console.log(selectedCountry)
  }, [selectedCountry])

  useEffect(() => {
    console.log(selectedPlant)
  }, [selectedPlant])

  const changeFormat = (inputDate) => {
    // Split the input date into year, month, and day
    const [year, month, day] = inputDate.split("-");

    // Create a new Date object
    const date = new Date(year, month - 1, day);

    // Extract day, month, and year from the date object
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();

    // Form the desired format
    const formattedDate = `${dd}-${mm}-${yyyy}`;

    return formattedDate; 
  }

  useEffect(() => {
    if (startDateFacilitator > endDateFacilitator) {
      alert("invalid date selection")
      return
    }

    console.log("running")

    let url = "analytics/insights/active-facilitator/?";
    if (selectedPlantFacilitator) {
      url += `plant=${selectedPlantFacilitator}`;
    }
    if (selectedStateFacilitator) {
      url += `&state=${selectedStateFacilitator}`;
    }
    if (selectedCountryFacilitator) {
      url += `&country=${selectedCountryFacilitator}`;
    }
    if (startDateFacilitator) {
      url += `&start_date=${startDateFacilitator}`;
    }
    if (endDateFacilitator) {
      url += `&end_date=${endDateFacilitator}`;
    }

    axiosInstance
      .get(url)
      .then(res => {
            const response = res.data.data
            setFacilitatorData(response)
      })
      .catch(err => {
        console.error(err)
      })

  }, [startDateFacilitator, endDateFacilitator, selectedPlantFacilitator, selectedCountryFacilitator, selectedStateFacilitator])

  useEffect(() => {
    if (selectedPlantFacilitator) {
      axiosInstance
        .get(`analytics/insights/active-facilitator/?plant=${selectedPlantFacilitator}`)
        .then(res => {
          const response = res.data.data
          setFacilitatorData(response)
        })
        .catch(err => {
          console.error(err)
        })
      }
  }, [ selectedPlantFacilitator ])

  useEffect(() => {
    if (searchInput) {
      const filterredList = userList.filter(item => item.full_name.toLowerCase().startsWith(searchInput.toLowerCase()))
      setFilteredUser(filterredList)
    } else if (searchInput === "" ) {
      setFilteredUser(userList)
    }
  }, [searchInput, userList])

  const DataFeedback = {
    text: "Insights",
    textsmall:
      "Understand how the employees are engaging with content to drive improved decision-making and identify the champions and future leaders",
  };

  const ColorBoder = {
    color2: "11.229px solid #4AB9FF",
    color1: "11.229px solid #70C968",
  };
  
  const handleDownload = (certificateUrl, certificateTitle) => {
    const link = document.createElement('a');
    link.href = certificateUrl;
    link.download = `${certificateTitle}_Certificate.pdf`;
    link.click();
  };

  const smalCardText = {
    text1: "Checklist Views",
    text2: `${insightsData? insightsData.checklist_views : "0"}`,
    text3: "2.5% than last year month",
    text3Icon: Arrowss,
    text4: "Download Report",
    text4Icon: DownloadIcon,
    color: "linear-gradient(112deg, #4AB9FF 0.06%, #204F6D 101.82%)",
  };
  const smalCardText2 = {
    text1: "Assessment Attempts",
    text2: `${insightsData? insightsData.assessment_views : "0"}`,
    text3: "2.5% than last year month",
    text3Icon: Arrowss,
    text4: "Download Report",
    text4Icon: DownloadIcon,
    color: "linear-gradient(112deg, #70C968 0.14%, #10490B 105.05%)",
  };
  const smalCardText3 = {
    text1: "Training Due in next 30 Days",
    text2: `${insightsData? insightsData.training_due_month : "0"}`,
    text4: "Download Report",
    text4Icon: DownloadIcon,
    color: "linear-gradient(112deg, #4AB9FF 0.06%, #204F6D 101.82%)",
  };
  const smalCardText4 = {
    text1: "Training Overdue",
    text2: `${insightsData? insightsData.training_overdue : "0"}`,
    text4: "Download Report",
    text4Icon: DownloadIcon,
    color:
      "linear-gradient(112deg, #DA2129 0.14%, #7F1418 105.05%), linear-gradient(112deg, #4AB9FF 0.06%, #204F6D 101.82%)",
  }
  
  // fetch main analytics data
  useEffect(() => {
    axiosInstance
      .get('analytics/insights/')
      .then((res) => {
        const response = res.data.data
        setInsightsData(response)
        setFacilitatorData(response.most_active_facilitators)
        setEmployeesData(response.most_active_employees)
        setContentCards(response.course_learning_stats)
      })
      .catch((err) => {
        console.error(err)
      })

    axiosInstance
      .get("accounts/api/users")
      .then(res => {
        setUserList(res.data.data)
        setFilteredUser(res.data.data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    if (startDate > endDate) {
      alert("invalid date selection")
      return
    }

    console.log("reached")

    let url = "analytics/insights/?";
    if (selectedPlant) {
      url += `plant=${selectedPlant}`;
    }
    if (selectedState) {
      url += `&state=${selectedState}`;
    }
    if (selectedCountry) {
      url += `&country=${selectedCountry}`;
    }
    if (startDate) {
      url += `&start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }

    axiosInstance
      .get(url)
      .then(res => {
        const response = res.data.data
        setInsightsData(response)
        setFacilitatorData(response.most_active_facilitators)
        setEmployeesData(response.most_active_employees)
        setContentCards(response.course_learning_stats)
      })
      .catch(err => {
        console.error(err)
      })

  }, [startDate, endDate, selectedPlant, selectedCountry, selectedState])

  useEffect(() => {
    console.log(popUpFormVisible)
  }, [popUpFormVisible])

  const handleDropdownClick = (id) => {
    setPopUpFormVisible(true)
    setSelectedUser(id)
  }

  const filterCourses = (input) => {
    if (input) {
      let filterred = insightsData.course_learning_stats.filter(item => item.name.toLowerCase().startsWith(input.toLowerCase()))
      setContentCards(filterred)
    } else if (!input) {
      setContentCards(insightsData.course_learning_stats)
    }
  }

  const handletableDownload = (tableName) => {
    const doc = new jsPDF("landscape");
    doc.text(`Most Active ${tableName}`, 10, 8);
    const headers = [
      "S.NO",
      "name",
      "Training",
      "Assessment",
      "Checklist",
      "Guidebook",
      "Case Study",
      "SOP Documents",
    ];
    const rows = facilitatorData.map((data, index) => [
      index + 1,
      data.facilitator,
      data.training,
      data.assessnment,
      data.checklist,
      data.guidebook,
      data.case_study,
      data.sop_documents,
    ]);
    const columnWidth = [3, 25, 25, 30, 25, 40, 25, 35];
    const rowHeight = 6;

    doc.autoTable({
      head: [headers],
      body: rows,
      columnWidth: columnWidth,
      rowHeight: rowHeight,
      styles: { cellPadding: 2, valign: "middle", halign: "center" },
    });

    doc.save("Track Application History.pdf");
  };

  const handleReportDownload = () => {
    const doc = new jsPDF("landscape");
    doc.text(`Insighs Full Report`, 10, 8);
    const headers = [
      "S.NO",
      "Total Training",
      "checklist views",
      "assessment views",
      "training due month",
      "training overdue",
    ];
    const rows = [
      1,
      insightsData.total_training,
      insightsData.checklist_views,
      insightsData.assessment_views,
      insightsData.training_due_month,
      insightsData.training_overdue,
    ];
    const columnWidth = [3, 25, 25, 30, 25, 40];
    const rowHeight = 6;

    doc.autoTable({
      head: [headers],
      body: [rows],
      columnWidth: columnWidth,
      rowHeight: rowHeight,
      styles: { cellPadding: 2, valign: "middle", halign: "center" },
    });

    doc.save("Insights.pdf");
  }

  const handleDataReportDownload = () => {
    const doc = new jsPDF("landscape");
    doc.text(`Insighs Total Training`, 10, 8);
    const headers = [
      "S.NO",
      "Total Training",
    ];
    const rows = [
      1,
      insightsData.total_training,
    ];
    const columnWidth = [3, 25];
    const rowHeight = 6;

    doc.autoTable({
      head: [headers],
      body: [rows],
      columnWidth: columnWidth,
      rowHeight: rowHeight,
      styles: { cellPadding: 2, valign: "middle", halign: "center" },
    });

    doc.save("totalTraining.pdf");
  }

  const handleCrossIcon = () => {
    setShowDropDown(false)
    setSearchInput("")
  }

  useEffect(() => {
    console.log(filteredUser)
  }, [filteredUser])

  const handleFilterDropDown = (value, selected, setSelected) => {
    if (!selected.includes(value)) {
      setSelected(prev => [...prev, value])
    } else if (selected.includes(value)) {
      const filtered = selected.filter(item => item !== value)
      setSelected(filtered)
    }
  }

  return (
    <>
      <Box sx={insightContainer}>

        {popUpFormVisible &&
        <div className="contribution-pop-up-container">
          <Contribution 
            user={selectedUser} 
            setPopUpFormVisible={setPopUpFormVisible}
          />
        </div>}

        <HeadingContent data={DataFeedback} />
        <Box sx={selectBox}>

        <div className="filter-select-div">
          <p className="filter-text">Country</p>
          <div className="dropdown-options-div">
            {countriesList && countriesList?.map((item, index) => (
              <p
                key={index} 
                className={(selectedCountry.includes(item.id))? "dropdown-options selected" : "dropdown-options"}
                onClick={() => handleFilterDropDown(item.id, selectedCountry, setselectedCountry)}
              >
                {item.country}
              </p>
            ))}
          </div>
        </div>


        <div className="filter-select-div">
            <p className="filter-text">State</p>
            <div className="dropdown-options-div">
              {stateList && stateList?.map((item, index) => (
                <p
                  key={index} 
                  className={(selectedState.includes(item.id))? "dropdown-options selected" : "dropdown-options"}
                  onClick={() => handleFilterDropDown(item.id, selectedState, setSelectedState)}
                >
                  {item.state}
                </p>
              ))}
            </div>
          </div>

          <div className="filter-select-div">
            <p className="filter-text">Plant</p>
            <div className="dropdown-options-div">
              {plantList && plantList?.map((item, index) => (
                <p
                  key={index} 
                  className={(selectedPlant.includes(item.id))? "dropdown-options selected" : "dropdown-options"}
                  onClick={() => handleFilterDropDown(item.id, selectedPlant, setSelectedPlant)}
                >
                  {item.plant}
                </p>
              ))}
            </div>
          </div>

          <div className="date-filter-container">
            <div className="date-selector-div">
              <input 
                type="date" 
                placeholder="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(changeFormat(e.target.value))}
                className="date-selector"
              />
            </div>
            <div className="date-selector-div">
              <input 
                type="date" 
                placeholder="End Date"
                value={endDate}
                onChange={(e) => setEndDate(changeFormat(e.target.value))}
                className="date-selector"
              />
            </div>
          </div>
        </Box>

        <Box sx={selectBox}>
          <div style={{ ...selectStyles }} className="search-box-container">
            <input
              className="search-box"
              type="text"
              value={searchInput}
              onFocus={() => setShowDropDown(true)}
              placeholder="Search by name & id"
              onChange={(e) => setSearchInput(e.target.value)}
            />

            {showDropDown &&
            <div onClick={handleCrossIcon} className="cross-icon">
              <IoCloseOutline />
            </div>}

            <div className="search-icon">
              <IoSearch />
            </div>

            {showDropDown && 
            <div className="dropdown-container">
                {filteredUser && filteredUser.length > 0 &&
                filteredUser.map((item) => (
                  <div className="user-row" key={item.id}>
                    <div className="user-info-dropdown">
                      <p className="username">{item.full_name}</p>
                      <div className="dropdown-tags">
                        <p className="user-tags">{item.department}</p>
                        <p className="user-tags">{item.designation}</p>
                        <p className="user-tags">{item.email}</p>
                      </div>
                    </div>
                    <p className="report-btn" onClick={() => handleDropdownClick(item.id)}>View Report</p>
                  </div>
                ))}
            </div>}
          </div>
        </Box>

        {/* Display cards Boxex */}
        <Box sx={displayCardBox}>
          <Box sx={trainingSessionBox}>
            <Typography sx={trainingText}>Total Training Sessions </Typography>
            <Typography sx={numberText}>{insightsData && insightsData.total_training} </Typography>
            <Box sx={displayBoxcd}>
              <Box>
                <Typography sx={lastText}>
                  {" "}
                  <img
                    src={Arrowss}
                    alt="Eye Icon"
                    style={{ marginRight: 1 }}
                  />{" "}
                  2.3% than last month
                </Typography>
                <Typography onClick={handleDataReportDownload} sx={lastDownloadTexts}>
                  {" "}
                  <img src={DownloadIcon} alt="Eye Icon" onClick={handleDownload} /> Download Report
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={smallBoxCOntainer}>
            <SmallCard data={smalCardText} />
            <SmallCard data={smalCardText2} />
            <SmallCard data={smalCardText3} reportUrl={"analytics/learning-path/download/training-due"}/>
            <SmallCard data={smalCardText4} />
          </Box>

          <button style={{ ...buttonbg, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={handleReportDownload}>
            {" "}
            <img src={DownloadIcon} alt="Eye Icon" style={{ width: "20px", }} />
            Download Full Report
          </button>

          {/* swiper part */}
        </Box>

        {/* most viewed content */}
        <Box sx={SwiperTextBox}>
          <Typography sx={swiperHeadder}>Most Viewed Content</Typography>
          <Typography sx={swiperHeadderBodder}></Typography>
          <Typography marginTop={"-15px"}>
            {" "}
            <img src={RightLeft} alt="nothing" />
          </Typography>
        </Box>

        <Box sx={selectBox}>

          <div style={{ ...selectStyles }}  className="search-box-container">
            <input
              className="search-box"
              type="text"
              placeholder="Search by course name"
              onChange={(e) => filterCourses(e.target.value)}
            />

            <p className="search-icon">
              <IoSearch />
            </p>
          </div>

        </Box>

        <div
          style={{
            marginBottom: 10,
            paddingLeft: "3rem",
            paddingRight: "3rem",
          }}
        >
          <SwiperInsight insightsData={contentCards}/>
        </div>

        <Box sx={ContentBOX}>
          {insightsData && 
            <ContentBox 
              plantList={plantList}
              color={ColorBoder.color1}
              reference={"Facilitator"} 
              rowData={facilitatorData}
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDateFacilitator}
              setStartDate={setStartDateFacilitator}
              handletableDownload={handletableDownload}
              selectedPlant={selectedPlantFacilitator}
              setSelectedPlant={setSelectedPlantFacilitator}
              selectedState={selectedStateFacilitator}
              setSelectedState={setSelectedStateFacilitator}
              selectedCountry={selectedCountryFacilitator}
              setselectedCountry={setselectedCountryFacilitator}
              handleFilterDropDown={handleFilterDropDown}
            />}
          {insightsData && 
            <ContentBox 
              plantList={plantList}
              color={ColorBoder.color2} 
              reference={"Employees"} 
              rowData={employeesData}
              handletableDownload={handletableDownload}
              selectedPlant={selectedPlantEmployees}
              setSelectedPlant={setSelectedPlantEmployees}
              selectedState={selectedStateFacilitator}
              setSelectedState={setSelectedStateFacilitator}
              selectedCountry={selectedCountryFacilitator}
              setselectedCountry={setselectedCountryFacilitator}
              handleFilterDropDown={handleFilterDropDown}
              // setEndDate={setEndDate}
              // setStartDate={setStartDate}
            />}
        </Box>
      </Box>
    </>
  );
}