import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import InputSelect from "../../smallComponent/InputSelect";
import {
  inputDetailBox,
  inputDetailText,
  labelBox,
  plantext,
  accesContainer
} from "../../../Styles/AdminManagement/CreateAdminStyle";
import { selectStyles, optionBg } from '../../../Styles/HeadingStyle/InputSelectStyle';
import CrossImage from "../../../Assets/icons/cross.svg"

export default function Accessibility({ 
  plantList,
  rightsList,
  selectedRights,
  setSelectedRights,
  plantAccess,
  setPlantAccess
  }) {
  const [ plantNames, setPlantNames ] = useState()

  useEffect(() => {
    if (plantList && plantAccess) {
      const plants = plantList.filter(item => plantAccess.includes(item.id));
      setPlantNames(plants);
    }    
  }, [plantAccess, plantList])

  const handleCheckBoxChange = (id) => {
    if (selectedRights.includes(id)) {
      setSelectedRights(prev => prev.filter(item => item !== id))
    } else if (!selectedRights.includes(id)) {
      setSelectedRights(prev => [...prev, id])
    }
  };

  const handlePlantAccessArray = (id) => {
    if (id && !plantAccess.includes(id)) {
      setPlantAccess(prev => [...prev, parseInt(id)])
    }
  }

  const handleRemovePlantAccess=(id)=>{
    setPlantAccess(prev => prev.filter(item => item !== id))
  }

  return (
    <>
      <Box sx={accesContainer}>
        <Box display="flex" alignItems="center">
          {rightsList.map((item, id) => (
            <div key={id}>
              <input
                style={{
                  marginRight: '5px',
                }}
                id={`CheckBox-${id}`}
                type="checkbox"
                checked={selectedRights.includes(item.id)}
                onChange={() => handleCheckBoxChange(item.id)}
              />
              <label
                htmlFor={`CheckBox-${id}`}
                style={{
                  ...labelBox,
                  lineHeight: '43.75px',
                }}
              >
                {item.rights}
              </label>
            </div>
          ))}
      </Box>
    
        <Box align="left" sx={inputDetailBox}>
          {" "}
          <Typography sx={inputDetailText} component={"span"}>
            {" "}
            Plant Access:
          </Typography>
          <Typography marginTop={1}>
            <select 
              name="Plant" 
              style={{ ...selectStyles }} 
              onChange={e => handlePlantAccessArray(e.target.value)}
            >
              <option default value="" style={{ ...optionBg }}>Plant</option>
              {plantList?.map((item, index) => (
                    <option 
                      key={index} 
                      value={item.id}
                      style={{ ...optionBg }}
                    >
                      {item.plant}
                    </option>
                ))}
            </select>
          </Typography>
        </Box>
        <Box align="left" sx={inputDetailBox}>
          {plantNames?.map((item, index)=>(
            <Box key={index} display="flex" flexDirection="row" marginRight={"1rem"}>

              <Typography component={"span"} key={index} sx={plantext} >
                {item.plant}
              </Typography> 

              <Typography component={"span"} onClick={()=>handleRemovePlantAccess(item.id)}>
                <img
                  src={CrossImage}
                  style={{ marginLeft:"-1rem"}}
                  alt="Cross"
                />
              </Typography>

            </Box>
          ))}
        </Box>
       
      </Box>
    </>
  );
}
