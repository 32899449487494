export const headingBox={
    width:"100%",
 
    paddingTop:5,
    paddingBottom:5
}
export const nameBox={
    display:"flex",
    flexDirextion:"row",
    paddingLeft:7,
    
}
export const textHeader = {
    color: "#FFF",
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26.25px" ,
    paddingRight:1// You can use quotes for consistency, but it's not required
};
export const texthead = {
    color: "#989898",
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 350, // Note: Font weight is usually specified using numeric values
    lineHeight: "120%",
    textAlign:"start",
    paddingLeft:7,
    marginTop:2 // You can use quotes for consistency, but it's not required
};
