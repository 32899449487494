import React, { useState, useEffect, useCallback } from "react";
import "../../Styles/AdminManagement/adminmanagement.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import {
  AdminContainerBox,
  modifyText,
  AdminButtonBox,
  SelectGroup,
  buttonbg,
  cellBody,
  tableBox,
  tableStyle,
  bodyBox,
  bodyCell,
  tableData,
  modifyDisplay,
  belowTableTBox,
} from "../../Styles/AdminManagement/AdminStyle";
import { Link } from "react-router-dom";
import HeadingContent from "../smallComponent/HeadingContent";
import MainButton from "../smallComponent/MainButton";
import AdminProfiles from "./AdminProfile/AdminProfile";
import ButtonAdmin from "./AdminProfile/ButtonAdmin";
import Image from "../../Assets/icons/Setting.svg";
import axiosInstance from "../../interceptor/axiosInstance";
import { useGlobalStateContext } from "../../states/GlobalState";

export default function AdminManagement() {
  const { setAlert } = useGlobalStateContext()
  const navigate = useNavigate();
  const DataFeedback = { text: "Admin Management" };

  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState();
  const [newUserList, setNewUserList] = useState();
  const [totalItems, setTotalItems] = useState();
  const [totalPages, setTotalPages] = useState();
  const [currentData, setCurrentData] = useState();
  const [accessUserId, setAccessUserId] = useState();

  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    if (newUserList && newUserList.length > 0) {
      setTotalItems(newUserList.length);
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setCurrentData(newUserList.slice(startIndex, endIndex));
    }
  }, [newUserList, totalItems, itemsPerPage, startIndex, endIndex]);

  const addAccessNum = useCallback(() => {
    if (userList && userList.length > 0) {
      const list = userList.map((item) => {
        const rights = item.rights.length;
        const total = rights + (item.plant_access && 1);
        return { accesNum: total, ...item };
      });
      setNewUserList(list);
    }
  }, [userList, setNewUserList]);

  const sortByAccess = (value) => {
    console.log(value);
    if (value === "ascending") {
      const sorted = newUserList.sort((a, b) => a.accesNum - b.accesNum);
      setUserList(sorted);
      return;
    }

    if (value === "descending") {
      const sorted = newUserList.sort((a, b) => b.accesNum - a.accesNum);
      setUserList(sorted);
      return;
    }

    addAccessNum();
  };

  useEffect(() => {
    addAccessNum();
  }, [userList, addAccessNum]);

  useEffect(() => {
    axiosInstance
      .get("accounts/api/users")
      .then((res) => {
        const response = res.data.data;
        const sortedList = response.sort((a, b) => {
          const nameA = a.full_name.toLowerCase();
          const nameB = b.full_name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUserList(sortedList);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const generatePageNumbers = () => {
    const numberOfRows = 10;
    const pages = [];

    if (newUserList && newUserList.length > 0) {
      let pagesToShow = Math.ceil(newUserList.length / numberOfRows);

      let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

      if (endPage - startPage < pagesToShow - 1) {
        startPage = Math.max(1, endPage - pagesToShow + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  const goToUserDetails = (id) => {
    navigate("/Admin/CreateAdmin/Admin", { state: { response: id } });
  };

  useEffect(() => {
    if (accessUserId) {
      const userData = currentData.find(user => user.id === accessUserId)

      const data = {
        user_active: userData.user_active
      }

      axiosInstance
        .patch(`accounts/api/users/${accessUserId}/`, data)
        .then(() => setAlert(`Success, User is ${userData.user_active? "Active" : "In-active"}`))
        .catch(error => console.error(error))
    }
  }, [accessUserId, currentData])

  const toggleUserActive = (id) => {
    setCurrentData((prevData) => {
      const updatedData = prevData.map((user) => {
        if (user.id === id) {
          return { ...user, user_active: !user.user_active };
        }
        return user;
      });
      return updatedData;
    });

    setAccessUserId(id)
  };

  return (
    <>
      <Box sx={AdminContainerBox}>
        <HeadingContent data={DataFeedback} />
        <Box sx={AdminButtonBox}>
          <Box sx={SelectGroup}>
            <select
              className="sort-by-access-select"
              onChange={(e) => sortByAccess(e.target.value)}
            >
              <option value="null">Sort By Access</option>
              {["ascending", "descending"].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <MainButton name="Filter" width="123px" height="36px" />
          </Box>
          <Box>
            <Link to={"/Admin/CreateAdmin"}>
              <button style={buttonbg}>
                Create New Admin <span style={{ color: "#DA2128", paddingLeft: "4px" }}>+</span>
              </button>
            </Link>
          </Box>
        </Box>

        <Box sx={tableBox}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...cellBody, width: "250px", paddingTop: "20px" }}>
                  Name
                  <Typography sx={tableData}>
                    Showing {newUserList && newUserList.length} of {newUserList && newUserList.length} total users
                  </Typography>
                </th>
                <th style={{ ...cellBody, width: "350px", paddingTop: "20px" }}>
                  Access<Typography sx={tableData}>.</Typography>
                </th>
                <th style={{ ...cellBody, width: "250px", paddingTop: "20px" }}>
                  Action <Typography sx={tableData}>.</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData?
                currentData.length > 0 &&
                currentData.map((user, index) => (
                  <tr key={index} style={{ ...bodyBox, padding: "60px" }}>
                    <td style={bodyCell}>
                      {console.log(user)}
                      <AdminProfiles
                        email={user.email}
                        profileImage={user.profile_picture}
                        name={user.full_name}
                        role={user.role}
                      />
                    </td>
                    <td style={{ ...bodyCell }}>
                      {user.rights && user.rights.includes(1) && <ButtonAdmin position={"admin"} />}
                      {user.rights && user.rights.includes(2) && <ButtonAdmin position={"analytic"} />}
                      {user.rights && user.rights.includes(3) && <ButtonAdmin position={"assign"} />}
                      {user.plant_access && user.plant_access.length > 0 && (
                        <ButtonAdmin position={"plant"} />
                      )}
                    </td>
                    <td style={{ ...bodyCell }}>
                      <Box sx={modifyDisplay}>
                        <div
                          onClick={() => goToUserDetails(user.id)}
                          style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
                        >
                          <Typography component={"span"} sx={modifyText}>
                            <span style={{ marginRight: "4px", paddingTop: "1px" }}>
                              <img src={Image} alt={"nothing"} />
                            </span>
                            Modify
                          </Typography>
                        </div>
                        <div onClick={() => toggleUserActive(user.id)} className="switch">
                          <input
                            type="checkbox"
                            value={user.user_active}
                            checked={user.user_active}
                            onChange={() => null}
                          />
                          <span className="slider"></span>
                        </div>
                      </Box>
                    </td>
                  </tr>
                )) : 
                <ThreeDots
                  visible={true}
                  height="1.3rem"
                  width="3.2rem"
                  color="#fff"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />}
            </tbody>
          </table>
          <div
            style={{
              ...modifyDisplay,
              ...belowTableTBox,
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <Typography sx={{ ...tableData, marginLeft: 2, background: "none" }}>
              Showing {currentData && currentData.length} of {newUserList && newUserList.length} total users
            </Typography>
            <Box>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                style={{ color: "#9D9FA2" }}
                disabled={currentPage === 1}
              >
                {"<"}
              </Button>
              {generatePageNumbers().map((pageNumber, index) => (
                <span
                  key={index}
                  onClick={() => handlePageChange(pageNumber)}
                  style={{
                    cursor: "pointer",
                    margin: "0 5px",
                    fontWeight: pageNumber === currentPage ? "bolder" : "normal",
                    color: pageNumber === currentPage ? "red" : "white",
                  }}
                >
                  {pageNumber}
                </span>
              ))}
              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                style={{ color: "#9D9FA2", fontSize: "15px" }}
                disabled={endIndex >= (newUserList && newUserList.length)}
              >
                {">"}
              </Button>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
}
