export  const data = [
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },

    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },

    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
    { name: "John Doe", training: "123", assessment: "123", checklist: "123" },
    {
      name: "Jane Smith",
      training: "1234",
      assessment: "1245",
      checklist: "1234",
    },
    {
      name: "Bob Johnson",
      training: "1234",
      assessment: "123",
      checklist: "1234",
    },
    {
      name: "Sarah Brown",
      training: "1234",
      assessment: "1234",
      checklist: "1234",
    },
  ];