import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import axiosInstance from "../../../interceptor/axiosInstance";
import RedButton from "../../../globalComponents/redButton/RedButton";
import "../../../Styles/login/ForgotPassword.css"
  
const ForgotPassword = ({ setPopUpFormVisible }) => {
    const [ userName, setUserName] = useState('');
    const [ userEmail, setUserEmail] = useState('');
    const [ userPhNumber, setUserPhNumber] = useState();
    const [ contactData, setContactData] = useState({});
    const [ alertText, setAlertText ] = useState()
    const [ submitLoader, setSubmitLoader] = useState(false)
  
    useEffect(() => {
      setContactData({
        name: userName,
        ...(userEmail && { email: userEmail }),
        mobileNo: parseInt(userPhNumber),
      });
    }, [userName, userEmail, userPhNumber ])
  
    const handleSubmit = () => {
      setSubmitLoader(true)
      if (!userName || !phoneNumber || phoneNumber.toString().length < 10) {
        setAlertText("Please provide your Name and Ph number")
        setSubmitLoader(false)
        return
      }

      axiosInstance
        .post("accounts/askpassword", contactData)
        .then((res) => {
          setSubmitLoader(false)
          res.status === 201 && setAlertText("Sent Successfully")
        //   setPopUpFormVisible(false)
        })
        .catch((error) => {
          setSubmitLoader(false)
          console.log(error)
        });
    }
  
  //   bqwfqoiwlendoqwkedmoqiwjdqwiebh dqwoedjqwedljkqw
  
  const email = "dsslearningtrail@gmail.com"
  const phoneNumber = "+91 1234567890"

  const [ showAlert, setShowAlert ] = useState(false)

    useEffect(() => {
        let timer;
        if (alertText) {
            setShowAlert(true);
            timer = setTimeout(() => {
                setShowAlert(false);
                setAlertText(null)
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [alertText, setAlertText]);
  
  const handleNameChange = (value) => {
  setUserName(value);
  };

  const handleEmailChange = (value) => {
  setUserEmail(value);
  };

  const handlePhNumberChange = (value) => {
  setUserPhNumber(value);
  };

    return (
      <div className='name-not-found-main-div'>
              
      {showAlert && 
      <div className='alert-box'>
          <p className='alert-text'>{alertText}</p>
      </div>}
  
      <div 
          onClick={() => setPopUpFormVisible(false)}
          className='cross-icon'>
          <RxCross2 />
      </div>
  
      <p className='page-title'>Forgot Password?</p>
  
      <div className='note'>
          You can contact us at <br/>
          <span>{email}</span>
          {" "}or{" "}
          <span>{phoneNumber}</span>
      </div>
  
      {/* <DividerOr /> */}
  
      <p className='request-call'>
          Request a Call back
      </p>
  
      <p className='grey-text'>
          Submit your details to the concerned department
      </p>
  
      <input
          className='name-not-found-input'
          placeholder='Your Full Name*'
          value={userName}
          onChange={(e) => handleNameChange(e.target.value)}
          required
      />
  
      <input
          className='name-not-found-input'
          type='email'
          placeholder='Email Address'
          onChange={(e) => handleEmailChange(e.target.value)}
          required
      />
  
      <input
          className='name-not-found-input'
          placeholder='Phone Number*'
          type='number'
          value={userPhNumber}
          onChange={(e) => handlePhNumberChange(e.target.value)}
          required
      />
  
      <RedButton text={"Submit"} onClickHandler={handleSubmit} loading={submitLoader} />
  
  </div>
    );
  };
  
  export default ForgotPassword;