import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Assign/assign.css";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import 'swiper/css';
import {
  assignContainer,
  searchBoxContainer,
  assignBox,
  profileSearch,
  adminProfileBox,
  AssignContBox,
  AssignSwiperTextBox,
  AssignswiperHeadder,
  AssignSwiperContainer,
  AssignswiperHeadderBodder,
  contentBoxSwiper,
  assignPersonText,
  assignPersonTextHover,
} from "../../Styles/Assign/AssignStyle";
import HeadingContent from "../smallComponent/HeadingContent";
import AssignBox from "./AssignBox/AssignBox";
import SearchIcon from "@mui/icons-material/Search";
import Polygon from "../../Assets/assign/Polygon.svg";
import {
  iconSearchMessage,
  ProfileText1,
  ProfileText2,
  imageProfileBox,
} from "../../Styles/AdminManagement/AdminStyle";
// import RightLeft from "../../Assets/Insight/RightLeft.svg";
import Person from "../../Assets/assign/person.svg"
import profilePlaceholder from "../../Assets/placeHolder/profilePlaceHolder.png"
import axiosInstance from "../../interceptor/axiosInstance";
import AssignModal from "./AssignModal/AssignModal";

export default function Assign() {
  const options = ['mandatory', 'advised', 'others'];
  const swiperRef = useRef(null);
  const [ userListData, setUserListData ] = useState()
  const [ nonAssignedUsers, setNonAssignedUsers ] = useState()
  const [ userList, setUserList ] = useState()
  const [ userId, setUserId ] = useState()
  const [ profile, setProfile] = useState(0);
  const [ searchInput, setSearchInput] = useState('')
  const [ searchValues, setSearchValues] = useState()
  const [ assignList, setAssignList ] = useState()
  const [ courseList, setCourseList ] = useState()
  const [ designations, setDesignations ] = useState()
  const [ showMenu, setShowMenu ] = useState(false)
  const [ activeBtn, setActiveBtn ] = useState(1)
  

  // useEffect(() => {
  //   console.log(assignList)
  // }, [assignList])

  useEffect(() => {
    setSearchValues(userList)
  }, [userList])

  const DataFeedback = {
    text: "Assign the training courses",
    textsmall:
      "The following employees are not given any training yet. Please assign.",
  };

  useEffect(() => {
    if (userList && userList.length > 0) {
      const uniqueDesignations = Array.from(new Set(userList.map(item => item.designation)));
      setDesignations(uniqueDesignations)
    }
  }, [userList])

  useEffect(() => {
    axiosInstance
      .get('accounts/api/users')
      .then((res) => {
        const response = res.data.data
        const sortedList = response.sort((a, b) => {
            const nameA = a.full_name.toLowerCase();
            const nameB = b.full_name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
          })
        setUserListData(sortedList)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    axiosInstance
      .get('accounts/static-data/')
      .then((res) => {
        const response = res.data.data
        const sortedList = response.non_assigned_users.sort((a, b) => {
            const nameA = a.full_name.toLowerCase();
            const nameB = b.full_name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
          })
        setNonAssignedUsers(sortedList)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    if (activeBtn === 1) { 
      setUserList(userListData)
      if (userListData && userListData.length > 0) {
        setUserId(userListData[0].id);
      }
      return
    } else if (activeBtn === 2) {
      setUserList(nonAssignedUsers)
      if (nonAssignedUsers && nonAssignedUsers.length > 0) {
        setUserId(nonAssignedUsers[0].id);
      }
      return
    }
  }, [activeBtn, userListData, nonAssignedUsers])

  useEffect(() => {
    if(userId) {
      axiosInstance
        .get(`assign/user-assign/?user=${userId}`)
        .then(res => {
          const response = res.data.data
          console.log(response)
          setAssignList(response)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [userId])

  const fetchAssign = () => {
    if(userId) {
      axiosInstance
        .get(`assign/user-assign/?user=${userId}`)
        .then(res => {
          const response = res.data.data
          console.log(response)
          setAssignList(response)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  useEffect(() => {
    if(userId) {
      axiosInstance
        .get(`courses/course`)
        .then(res => {
          const apiData = res.data.data;
          const sortedData = apiData.sort((a, b) => a.id - b.id);
          setCourseList(sortedData);
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [userId])

  const handleClickProfile = (id, index) => {
    console.log(id)
    setUserId(id)
    setProfile(index);
  };

  useEffect(() => {
    if (searchInput && userList) {
        const userArray = userList.filter((item) => {
          const fullname = item.full_name.toLowerCase();
          const inputName = searchInput.toLowerCase()

          return inputName && fullname.startsWith(inputName) && inputName !== fullname
        })
        .slice(0, 3)
      setSearchValues(userArray)
    } else if (!searchInput) {
      setSearchValues(userList)
    }
  }, [searchInput, userList])

  const handleOptionChange = (category, value, assignId, courseId, newId) => {
    console.log(category, value)
    if (value && courseId && assignId) {
      setAssignList(prev => {
        const updatedData = [ ...prev] ; 
        const assignObject = prev.find(item => item.course === courseId)
        assignObject[category] = value; 
        return updatedData;
        });
    } else if (value && !assignId && !newId) {
      setAssignList(prev => {
        const array = [...prev]
        const data = {
          newId: (array.length + 1),
          course: courseId,
          user: userId,
          [category] : value,
          // assignId: 
        }

        console.log(data)

        array.push(data)

        return array
      })
    } else if (value && newId) {
      setAssignList(prev => {
        const updatedData = [ ...prev] ; 
        const assignObject = prev.find(item => item.newId === newId)
        assignObject[category] = value; 
        return updatedData;
        });
    }
  };

  const handleDaysSelection = (value, assignId, courseId, newId) => {
    if (value && courseId && assignId) {
      setAssignList(prev => {
        const updatedData = [ ...prev] ; 
        const assignObject = prev.find(item => item.course === courseId)
        assignObject["refresher_days"] = value; 
        console.log(assignObject);
        return updatedData;
        });
    } else if (value && !assignId && !newId) {
      setAssignList(prev => {
        const array = [...prev]
        const data = {
          newId: (array.length + 1),
          course: courseId,
          user: userId,
          refresher_days : value,
          // assignId: 
        }

        console.log(data)

        array.push(data)

        return array
      })
    } else if (value && newId) {
      setAssignList(prev => {
        const updatedData = [ ...prev] ; 
        const assignObject = prev.find(item => item.newId === newId)
        assignObject["refresher_days"] = value; 
        console.log(assignObject);
        return updatedData;
        });
    }
  }

  const findAssignData = (courseId) => {
    if (assignList && assignList.length > 0) {
      const assignData = assignList.find(item => item.course === courseId)
      if (assignData) {
        return assignData
      } else {
        return null
      }
    } else if (assignList && assignList.length === 0) {
      return null
    }
  }

  return (
    <>
      <Box sx={assignContainer}>
        <HeadingContent data={DataFeedback} />
        <Box sx={assignBox}>
          {/*searchBoxContainer   */}
          <Box sx={searchBoxContainer}>
            <div className="assign-user-btns-div">
              <button 
                className={activeBtn === 1? "userlist-btn active-assign-btn" : "userlist-btn"}
                onClick={() => setActiveBtn(1)}
              >
                All Users
              </button>
              <button 
                className={activeBtn === 2? "userlist-btn active-assign-btn" : "userlist-btn"}
                onClick={() => setActiveBtn(2)}
              >
                Pending
              </button>
            </div>
            <Box align="center" sx={{ position: "relative", width: "90%" }}>
              <input
                type="text"
                placeholder="Enter user name here..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                style={{
                  border: "1px solid #212121",
                  borderRadius: "8.889px",
                  background: "rgba(47, 47, 47, 0.85)",
                  boxShadow: "0px 1.111px 8.889px 0px rgba(0, 0, 0, 0.11)",
                  width: "90%",
                  padding: "0.8rem", // Adjust padding for the icon
                  color: "white", // Adjust text color as needed
                }}
              />
              <SearchIcon
                style={{
                  color: "red",
                  position: "absolute",
                  top: "50%",
                  right: "1rem", // Adjust the right distance as needed
                  transform: "translateY(-50%)",
                  "@media (minWidth: 1341px)": {
                    left: "10px",
                  },
                }}
            />
            
            </Box>

            <Box sx={profileSearch}>
              {searchValues && searchValues.map((user, index) => (
                <Box
                  onClick={() => {
                    handleClickProfile(user.id , index);
                  }}
                  sx={{
                    ...iconSearchMessage,
                    ...adminProfileBox,
                    textTransform: 'uppercase',
                    background: profile === index ? "#2A2A2A" : "",
                    width: "108%",
                    cursor:"pointer"
                  }}
                  key={index}
                >
                  <Typography color={"white"} sx={imageProfileBox}>
                    <img
                      src={user.profile_picture || profilePlaceholder}
                      style={{ width: "50px", cursor: "pointer" }}
                      alt="logo"
                    />
                  </Typography>
                  <Box color={"white"} marginLeft={2}>
                    <Typography
                      sx={{
                        ...ProfileText1,
                        color: profile === index ? "#DA2128" : "#fff",
                      }}
                    >
                      {user.full_name}
                    </Typography>
                    <Typography sx={ProfileText2} align="left">
                      {user.role}
                    </Typography>
                  </Box>
                  {profile === index ? (
                    <img
                      src={Polygon}
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-19px", // Adjust the right position as needed
                        top: "50%", // Adjust the top position to center vertically
                        transform: "translateY(-50%)",
                      }}
                      alt="Polygon logo"
                    />
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Box>
          </Box>

          {/*Assign By profile  */}
          <Box sx={AssignContBox}>
            <Box sx={AssignSwiperTextBox}>
              <Typography sx={AssignswiperHeadder}>
                Assign by profile
              </Typography>
              <Typography sx={AssignswiperHeadderBodder}>
                  
              </Typography>

              <div className="swiper-btn" onClick={() => swiperRef.current.slidePrev()}>
                <FaChevronLeft />
              </div>

              <div className="swiper-btn" onClick={() => swiperRef.current.slideNext()}>
                <FaChevronRight />
              </div>
            </Box>
           
            <Box sx={AssignSwiperContainer}>
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              className="mySwiper"
              onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
            {designations?.map((item, index) => (
              <SwiperSlide key={index} onClick={() => setShowMenu(index)}>
                <Box key={index} style={{ textDecoration: 'none', color: 'inherit' }}>

                <Box
                  sx={{
                    position: 'relative',
                    ...contentBoxSwiper,
                    '&:hover': {
                      '&::before': assignPersonTextHover,
                    },
                  }}
                  // onClick={() => setShowMenu(index)}
                >
                  <img src={Person} alt="person" />
                  <Typography sx={assignPersonText}>{item}</Typography>
                </Box>

                </Box>
              </SwiperSlide>
            ))}
            </Swiper>

            {(typeof(showMenu) === "number") && 
              <AssignModal 
                designation={designations[showMenu]} 
                setShowMenu={setShowMenu} 
              />}
          </Box>

          {/* assign by topics */}
          <Box sx={AssignSwiperTextBox}>
              <Typography sx={AssignswiperHeadder}>
              Assign by topics
              </Typography>
              <Typography sx={AssignswiperHeadderBodder}></Typography>
           
            </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="1rem" marginTop="1rem">
          {courseList && assignList && courseList.length > 0 && courseList.map((item, index) => (
            <div style={{ width: "45%" }} key={index}>
              <AssignBox
                options={options}
                courseName={item.name}
                handleOptionChange={handleOptionChange}
                handleDaysSelection={handleDaysSelection}
                cardIndex={index}
                courseId={item.id}
                fetchAssign={fetchAssign}
                newId={(findAssignData(item.id)) && (findAssignData(item.id)).newId}
                assignId={(findAssignData(item.id)) && (findAssignData(item.id)).id}
                data={(findAssignData(item.id)) && (findAssignData(item.id))}
                trainingValue={(findAssignData(item.id)) && (findAssignData(item.id)).training  && (findAssignData(item.id)).training}
                assessmentValue={(findAssignData(item.id)) && (findAssignData(item.id)).assignment && (findAssignData(item.id)).assignment}
                checklistValue={(findAssignData(item.id)) && (findAssignData(item.id)).checklist && (findAssignData(item.id)).checklist}
                guidebookValue={(findAssignData(item.id)) && (findAssignData(item.id)).guidebook && (findAssignData(item.id)).guidebook}
                caseStudyValue={(findAssignData(item.id)) && (findAssignData(item.id)).casestudy && (findAssignData(item.id)).casestudy}
                SopDocsValue={(findAssignData(item.id)) && (findAssignData(item.id)).sop_document && (findAssignData(item.id)).sop_document}
              />
            </div>
          ))}
        </Box>
           {/* <Box sx={buttonBox} align="right">
             <button style={{...buttonReset,cursor: "pointer"}}>Reset</button>
             <button style={{...buttonReset,background:"#DA2128",width:"155px",cursor: "pointer"}}>Save & Exit</button>
           </Box> */}
         
          </Box>
        </Box>
      </Box>
    </>
  );
}
